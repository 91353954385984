import PropTypes from "prop-types";
import classnames from "classnames";

import "./spinner.scss";

function Spinner({ classes }) {
    const classComponent = classnames({
        component__spinner: true,
        [classes]: classes,
    });
    return (
        <div className={classComponent}>
            <div className="component__spinner--balls">
                <div />
                <div />
                <div />
            </div>
        </div>
    );
}

Spinner.propTypes = {
    classes: PropTypes.string,
};

export default Spinner;
