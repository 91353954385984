import client from "./client";

let controller;

export const DoGpsLookup = async (data) => {
    try {
        if (controller) controller.abort();
        controller = new AbortController();

        const response = await client.get("/cities/lookup", { params: data });
        return response.data;
    } catch (error) {
        return null;
    }
};
