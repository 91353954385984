import { Link } from "react-router-dom";

import AppImage1x from "./images/application-image-1x.png";
import AppImage2x from "./images/application-image-2x.png";
import AppImage3x from "./images/application-image-3x.png";

import "./section-app.scss";
import AppDownloadBtns from "../../../components/app-download/AppDownloadBtns";

function SectionApp() {
    return (
        <div className="section__app thin-custom-row">
            <div className="section__app--img">
                <img
                    src={AppImage1x}
                    srcSet={`${AppImage2x} 2x, ${AppImage3x} 3x`}
                    draggable={false}
                    alt="CharityRx App"
                />
            </div>
            <div className="section__app--content">
                <h2>CharityRx App</h2>
                <p>
                    Unlock instant savings on your prescriptions with our free
                    app! Easily compare prices at nearby pharmacies, access
                    exclusive discounts, and never pay full price for your
                    medications again.
                </p>
                <AppDownloadBtns />
                <Link to="app" className="section__app--content-btn">
                    <button className="button purple">See more</button>
                </Link>
            </div>
        </div>
    );
}

export default SectionApp;
