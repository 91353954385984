import { useState } from "react";
import classnames from "classnames";
import LoadingBtn from "../loading-btn/LoadingBtn";

import { validateEmail } from "../../utilities/helpers";
import { sendQuestion } from "../../services/mail.service";

import "./contact-us-form.scss";

function ContactUsForm() {
    const initedFormValues = { name: "", email: "", text: "" };
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [formValues, setFormValues] = useState(initedFormValues);

    const errorClassNames = classnames({
        "contact__form--error": true,
        show: error !== null,
    });

    const componentClassNames = classnames({
        "component__contact-form": true,
        "success-send": success,
    });

    const validateForm = ({ name, email, text }) => {
        let valid = true;
        if (name.length === 0) {
            setError("The name can not be empty, please set your name.");
            valid = false;
        }
        if (text.length === 0) {
            setError(
                "The question can not be empty, please set your question."
            );
            valid = false;
        }

        if (!validateEmail(email)) {
            setError("Invdalid email, please check and try again.");
            valid = false;
        }
        return valid;
    };

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        setSuccess(false);
        setError(null);

        if (validateForm(formValues)) {
            const result = await sendQuestion(
                formValues.email,
                formValues.name,
                formValues.text
            );
            if (result) {
                setSuccess(true);
                setTimeout(() => {
                    setFormValues(initedFormValues);
                    setSuccess(false);
                }, 2600);
            } else {
                setError("Something went wrong, please try again.");
            }
        }
        setLoading(false);
    };

    return (
        <div className={componentClassNames}>
            <form className="contact__form" onSubmit={onSubmitHandler}>
                <div className="contact__form--wrap">
                    <label className="contact__form--label">
                        How may we address you?
                        <input
                            value={formValues.name}
                            onChange={(e) =>
                                setFormValues({
                                    ...formValues,
                                    name: e.target.value,
                                })
                            }
                            type="text"
                            name="name"
                            placeholder="Name"
                            className="input form-text contact__form--input"
                        />
                    </label>
                </div>
                <div className="contact__form--wrap">
                    <label className="contact__form--label">
                        Your email address
                        <input
                            value={formValues.email}
                            onChange={(e) =>
                                setFormValues({
                                    ...formValues,
                                    email: e.target.value,
                                })
                            }
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            className="input form-text contact__form--input"
                        />
                    </label>
                </div>
                <div className="contact__form--wrap">
                    <label className="contact__form--label">
                        Have a question? Need help to solve an issue? Tell us
                        about it
                        <textarea
                            value={formValues.text}
                            onChange={(e) =>
                                setFormValues({
                                    ...formValues,
                                    text: e.target.value,
                                })
                            }
                            name="name"
                            placeholder="Enter your message"
                            className="contact__form--textarea"
                        />
                    </label>
                </div>
                <div className="contact__form--wrap">
                    <LoadingBtn
                        classNames="contact__form--submit"
                        btnText="Send"
                        isLoading={loading}
                    />
                </div>
                <div className={errorClassNames}>
                    <p>{error}</p>
                </div>
            </form>
            <div className="component__contact-form--success">
                <p className="success">
                    Thanks for your message! <br /> We&apos;ll get back to you
                    as soon as we can.
                </p>
            </div>
        </div>
    );
}

export default ContactUsForm;
