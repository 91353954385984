import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

import { SearchContext } from "../../stores/contexts";
import { getUserLocation } from "../../stores/location.store";
import { fetchGeoLocationFromBrowser } from "../../utilities/helpers";

import Spinner from "../spinner/Spinner";
import ModalWindow from "../modal-window/ModalWindow";
import LocationInput from "../location/LocationInput";
import LocationRadiusSlider from "../location/LocationRadiusSlider";

import "./modal-mobile-location.scss";

function ModalMobileLocation({ show, onChangeLocation, modalCloseHandler }) {
    const { packagesStore } = useContext(SearchContext);
    const [initedLocation, setInitedLocation] = useState(() => ({
        zip: "",
        radius: 0,
    }));
    const [zipValue, setZipValue] = useState("");
    const [radiusValue, setRadiusValue] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const location = getUserLocation();
        setInitedLocation({
            zip: location.zip,
            radius: location.radius,
        });
        setZipValue(location.zip);
        setRadiusValue(location.radius);
    }, [show]);

    useEffect(() => {
        onChangeLocation(zipValue, radiusValue);
    }, [zipValue, radiusValue]);

    // Check each value for should refresh location.
    const closeHandler = () => {
        const location = getUserLocation();
        let mustSearch = false;
        if (zipValue !== initedLocation.zip) {
            location.set(zipValue);
            mustSearch = true;
        }

        if (radiusValue !== initedLocation.radius) {
            location.setRadius(radiusValue);
            mustSearch = true;
        }

        if (mustSearch) {
            packagesStore.initSearch();
        }

        modalCloseHandler();
    };

    const onCurrentLocationHandler = () => {
        setLoading(true);
        fetchGeoLocationFromBrowser(true).then((result) => {
            setLoading(false);
            if (result !== false) {
                setZipValue(result.zipcode);
                packagesStore.initSearch();
                modalCloseHandler();
            }
        });
    };

    if (show === false) return null;

    return (
        <ModalWindow
            title="Edit your location"
            fullscreen={true}
            closeHandler={modalCloseHandler}
        >
            <div className="location-modal">
                <div className="location-modal__content">
                    <LocationInput
                        value={zipValue}
                        onChangeHandler={(val) => setZipValue(val)}
                        containerStyle="location-input"
                    />
                    <LocationRadiusSlider
                        value={radiusValue}
                        onChangeHandler={(val) => setRadiusValue(val)}
                    />
                </div>
                <div className="location-modal__bottom">
                    <button
                        className="button set-location"
                        onClick={() => onCurrentLocationHandler()}
                    >
                        Set up my current location
                    </button>
                    <button
                        className="button purple"
                        onClick={() => closeHandler()}
                    >
                        Submit
                    </button>
                </div>
                {loading ? <Spinner classes="location-modal__spinner" /> : null}
            </div>
        </ModalWindow>
    );
}

ModalMobileLocation.propTypes = {
    show: PropTypes.bool.isRequired,
    onChangeLocation: PropTypes.func.isRequired,
    modalCloseHandler: PropTypes.func.isRequired,
};

export default ModalMobileLocation;
