import client from "./client";

const ENDPOINT = "drugs";

export const DoGetDrugDescriptionRequest = async (name) => {
    try {
        const response = await client.post("/", {
            action: `${ENDPOINT}/drug/description`,
            data: { name },
        });

        return response.data;
    } catch (error) {
        // console.log("error: ", error);
        return null;
    }
};

export const DoGetDrugFiltersRequest = async (name) => {
    try {
        const response = await client.post("/", {
            action: `${ENDPOINT}/drug/filters`,
            data: { name },
        });

        return response.data;
    } catch (error) {
        // console.log("error: ", error);
        return null;
    }
};

export const DoGetPackagesRequest = async (ndcs) => {
    try {
        const response = await client.post("/", {
            action: `${ENDPOINT}/drug/packages`,
            data: { ndcs },
        });

        return response.data;
    } catch (error) {
        // console.log("error: ", error);
        return null;
    }
};

export const DoGetDrugSaveUpRequest = async (name) => {
    try {
        const response = await client.post("/", {
            action: `${ENDPOINT}/drug/saveup`,
            data: { name },
        });

        return response.data;
    } catch (error) {
        // console.log("error: ", error);
        return null;
    }
};

export const getSampleSavingRequest = async (zipcode) => {
    try {
        const response = await client.post("/", {
            action: `${ENDPOINT}/search/samples`,
            data: { zipcode },
        });

        return response.data;
    } catch (error) {
        // console.log("error: ", error);
        return null;
    }
};
