import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { SearchContext } from "../../../../stores/contexts";
import "./mobile-drug-prescription.scss";
import Spinner from "../../../../components/spinner/Spinner";
import ModalWindow from "../../../../components/modal-window/ModalWindow";

const RouteList = observer(() => {
    const { filtersStore } = useContext(SearchContext);
    const { routes, route } = filtersStore;

    const onClickHandler = ({ value }) => {
        filtersStore.selectRoute(value);
    };

    return (
        <ul className="prescription__list">
            {routes.map((item, key) => (
                <li
                    key={key}
                    className={`prescription__list--item ${
                        item?.value === route?.value ? "active" : ""
                    }`}
                    onClick={() => onClickHandler(item)}
                >
                    <span>{item.label}</span>
                </li>
            ))}
        </ul>
    );
});

const FormList = observer(() => {
    const { filtersStore } = useContext(SearchContext);
    const { forms, form } = filtersStore;

    const onClickHandler = ({ value }) => {
        filtersStore.selectForm(value);
    };

    return (
        <ul className="prescription__list">
            {forms.map((item, key) => (
                <li
                    key={key}
                    className={`prescription__list--item ${
                        item?.value === form?.value ? "active" : ""
                    }`}
                    onClick={() => onClickHandler(item)}
                >
                    <span>{item.label}</span>
                </li>
            ))}
        </ul>
    );
});

const StrengthsList = observer(() => {
    const { filtersStore } = useContext(SearchContext);
    const { strengths, strength } = filtersStore;

    const onClickHandler = ({ value }) => {
        filtersStore.selectStrength(value);
    };

    return (
        <ul className="prescription__list">
            {strengths.map((item, key) => (
                <li
                    key={key}
                    className={`prescription__list--item ${
                        item?.value === strength?.value ? "active" : ""
                    }`}
                    onClick={() => onClickHandler(item)}
                >
                    <span>{item.label}</span>
                </li>
            ))}
        </ul>
    );
});

const PackagesList = observer(() => {
    const { packagesStore } = useContext(SearchContext);
    const { options, selected, customUnit, state } = packagesStore;

    const onClickHandler = ({ value }) => {
        packagesStore.selectPackage(value);
    };
    const rednerSmallLoader = () => {
        if (state !== "done") {
            return <Spinner classes="prescription__list--loader" />;
        } else {
            return null;
        }
    };

    return (
        <ul className="prescription__list packages-list">
            {options.map((item, key) => (
                <li
                    key={key}
                    className={`prescription__list--item ${
                        customUnit === null && item?.value === selected?.value
                            ? "active"
                            : ""
                    }`}
                    onClick={() => onClickHandler(item)}
                >
                    <span>{item.label}</span>
                </li>
            ))}
            {rednerSmallLoader()}
        </ul>
    );
});

const PackagesCustomInput = observer(() => {
    const { packagesStore } = useContext(SearchContext);
    const { customUnit } = packagesStore;
    const [value, setValue] = useState(customUnit ? customUnit : "");

    const onChangeHandler = (e) => {
        const _value = e.target.validity.valid ? e.target.value : value;
        setValue(_value);
    };

    useEffect(() => {
        if (value && value != "") {
            packagesStore.setCustomUnit(value);
        }
    }, [value]);

    return (
        <div className="component__packages-custom-input">
            <label>
                Enter custom quantity
                <input
                    pattern="[0-9]*"
                    placeholder="0-999"
                    className={`input form-text package-input ${
                        customUnit !== null ? "active" : ""
                    }`}
                    value={value}
                    onChange={onChangeHandler}
                />
            </label>
        </div>
    );
});

function MobileDrugModal({ closeHandler }) {
    return (
        <ModalWindow
            fullscreen={true}
            scrollable={true}
            title="Edit your prescription"
            closeHandler={closeHandler}
        >
            <div className="prescription-modal">
                <div className="prescription-modal__content">
                    <div className="prescription-modal__content--item">
                        <p className="small-title">Route</p>
                        <RouteList />
                    </div>
                    <div className="prescription-modal__content--item">
                        <p className="small-title">Form</p>
                        <FormList />
                    </div>
                    <div className="prescription-modal__content--item">
                        <p className="small-title">Strengths</p>
                        <StrengthsList />
                    </div>
                    <div className="prescription-modal__content--item">
                        <p className="small-title">Packages</p>
                        <PackagesList />
                        <PackagesCustomInput />
                    </div>
                </div>
                <div className="prescription-modal__bottom">
                    <button className="button purple" onClick={closeHandler}>
                        Apply
                    </button>
                </div>
            </div>
        </ModalWindow>
    );
}

MobileDrugModal.propTypes = {
    closeHandler: PropTypes.func.isRequired,
};

export default MobileDrugModal;
