import { capitalize } from "lodash";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./drug-save-up.scss";
import { getDrugSaveUp } from "../../services/search.service";

function DrugSaveUp({ drug }) {
    const [link, setLink] = useState("search/");
    const [show, switchShow] = useState(false);
    const [text, setText] = useState();

    const transformSaveUpText = (saveUpObj) => {
        if (saveUpObj.savingPercent && saveUpObj.savingPercent > 1) {
            return `Save up to ${saveUpObj.savingPercent}% on ${capitalize(
                saveUpObj.type
            )} (${capitalize(saveUpObj.name)})`;
        } else {
            return `Save up to 85% on ${capitalize(
                saveUpObj.type
            )} (${capitalize(saveUpObj.name)})`;
        }
    };

    useEffect(() => {
        let didCancel = false;
        async function getData() {
            switchShow(false);
            const result = await getDrugSaveUp(drug);
            if (!didCancel) {
                if (result) {
                    setText(transformSaveUpText(result));
                    if (result.slug) {
                        setLink(`/drug/${result.slug}`);
                    }
                    switchShow(true);
                }
            }
        }
        getData();
        return () => {
            didCancel = true;
        };
    }, [drug]);

    const componentClassNames = classnames({
        "component__drug-saveup": true,
        show: show,
    });

    return (
        <div className={componentClassNames}>
            <div className="container">
                <div className="row">
                    <div className="drug-saveup__content">
                        <span className="drug-saveup__text">{text}</span>
                        <span className="drug-saveup__learn-more-btn">
                            <Link to={link}>Learn more</Link>
                        </span>
                        <span
                            className="drug-saveup__close-btn"
                            onClick={() => switchShow(false)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

DrugSaveUp.propTypes = {
    drug: PropTypes.string.isRequired,
};

export default DrugSaveUp;
