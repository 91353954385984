import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { SearchContext } from "../../stores/contexts";
import {
    fetchGeoLocationFromBrowser,
    InnerWidthForDesktop,
} from "../../utilities/helpers";
import SearchRootStore from "../../stores/search/search.root.store";

import DrugCard from "./drug-card/DrugCard";
import SearchResult from "./search-result/SearchResult";

import DrugPrescription from "./drug-prescription/DrugPrescription";
import SearchMobileContent from "./search-mobile-content/SearchMobileContent";
import PageSectionSubscription from "../../components/page-section-subscription/PageSectionSubscription";

import "./search-page.scss";
import DrugSaveUp from "../../components/drug-save-up/DrugSaveUp";
// import MobileDrugModal from "./search-mobile-content/mobile-drug-prescription/MobileDrugModal";

const MainSearchRootStore = new SearchRootStore();

function SearchPage() {
    const { drug: drugUri } = useParams();
    const [isDesktop, setIsDesktop] = useState(() => {
        return window.innerWidth > InnerWidthForDesktop;
    });

    useEffect(() => {
        fetchGeoLocationFromBrowser();
    }, []);

    useEffect(() => {
        const updateWindowDimensions = () => {
            setIsDesktop(window.innerWidth > InnerWidthForDesktop);
        };
        window.addEventListener("resize", updateWindowDimensions);
        return () =>
            window.removeEventListener("resize", updateWindowDimensions);
    }, []);

    return (
        <SearchContext.Provider value={MainSearchRootStore}>
            <div className="page-search-main-wrapper">
                {isDesktop ? (
                    <div className="page-search">
                        <div className="page-search__description">
                            <DrugCard drug={drugUri} />
                            <DrugPrescription drug={drugUri} />
                        </div>
                        <div className="page-search__drugsaveup">
                            <DrugSaveUp drug={drugUri} />
                        </div>
                        <div className="page-search__results">
                            <SearchResult />
                        </div>
                    </div>
                ) : (
                    <SearchMobileContent drug={drugUri} />
                )}
                <div className="container-fluid">
                    <div className="row">
                        <PageSectionSubscription />
                    </div>
                </div>
            </div>
        </SearchContext.Provider>
    );
}

export default SearchPage;
