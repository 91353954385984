import { Helmet } from "react-helmet-async";
import PageSectionSubscription from "../../components/page-section-subscription/PageSectionSubscription";
import CouponEmptyContent from "./coupon-content/CouponEmptyContent";

import "./get-coupon-page.scss";

function GetCardPage() {
    return (
        <div className="page-coupon">
            <Helmet>
                <title>
                    Rx Prescription Savings Card | Prescription Discount Card
                    Program - CharityRx
                </title>
                <meta
                    name="description"
                    content="Get our discount prescription card instantly, give it to your pharmacist, and get heavy discounts on your next prescription. Search and compare prescription prices."
                />
            </Helmet>
            <div className="container">
                <div className="page-coupon__content">
                    <CouponEmptyContent />
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <PageSectionSubscription />
                </div>
            </div>
        </div>
    );
}

export default GetCardPage;
