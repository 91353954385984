import PropTypes from "prop-types";
import { currencyFormat } from "../../../../utilities/helpers";

import PharmacyLogo from "../../../../components/pharmacy-logo/PharmacyLogo";
import GetACouponBtn from "../../../../components/get-a-coupon-btn/GetACouponBtn";
import ListPreferPriceShimmer from "../list-item-shimmer/ListPreferPriceShimmer";

import "../search-result.scss";
import { Fragment } from "react";

const PriceColumn = ({ itemPrice, itemNdc, pharmacyName }) => {
    return (
        <Fragment>
            {itemPrice === null ? (
                <ListPreferPriceShimmer />
            ) : (
                <Fragment>
                    <span className="price">{currencyFormat(itemPrice)}</span>
                    <GetACouponBtn ndc={itemNdc} pharmacyName={pharmacyName} />
                </Fragment>
            )}
        </Fragment>
    );
};

PriceColumn.propTypes = {
    itemPrice: PropTypes.any,
    itemNdc: PropTypes.string.isRequired,
    pharmacyName: PropTypes.string.isRequired,
};

function PreferResultItem({ pharmacyItem }) {
    return (
        <li className="search-result__item item-prefer">
            <div className="search-result__item--logo">
                <PharmacyLogo pharmacyName={pharmacyItem.baseName} />
            </div>
            <div className="search-result__item--content">
                <PriceColumn
                    itemPrice={pharmacyItem.price}
                    itemNdc={pharmacyItem.ndc11}
                    pharmacyName={pharmacyItem.baseName}
                />
            </div>
        </li>
    );
}

PreferResultItem.propTypes = {
    pharmacyItem: PropTypes.object.isRequired,
};

export default PreferResultItem;
