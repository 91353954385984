import { useState } from "react";
import classnames from "classnames";
import "./page-section-subscription.scss";
import LoadingBtn from "../loading-btn/LoadingBtn";
import { createSubscription } from "../../services/mail.service";
import { validateEmail } from "../../utilities/helpers";

function PageSectionSubscription() {
    const [loading, setLoading] = useState(false);
    const [success, setSuccessful] = useState(false);
    const [error, setError] = useState(false);
    const [email, setEmail] = useState("");

    const onChangeInputHandler = (e) => {
        setEmail(e.target.value);
    };

    const onSubmitForm = async (e) => {
        e.preventDefault();
        setSuccessful(false);
        setError(false);
        setLoading(true);
        if (email && email !== "" && validateEmail(email)) {
            const status = await createSubscription(email);
            if (status) {
                setLoading(false);

                setSuccessful(true);
                setTimeout(() => {
                    setSuccessful(false);
                }, 3500);
                setEmail("");
                return;
            }
        }
        setError(true);
        setLoading(false);
        return;
    };

    const errorClassNames = classnames({
        "section__subscription--error": true,
        show: error,
    });

    const componentClassNames = classnames({
        section__subscription: true,
        "show-success": success,
    });

    return (
        <div className={componentClassNames}>
            {!success ? (
                <>
                    <h2>Don’t miss out on savings!</h2>
                    <p>The best Rx savings delivered to your inbox!</p>
                </>
            ) : (
                <div className="section__subscription--success">
                    <h2>You&apos;ve been subscribed!</h2>
                    <p>
                        We will be bringing you all the latest news about
                        discount drug price coupons - right to your inbox.
                    </p>
                </div>
            )}

            <div className="section__subscription--form">
                <form className="subscription__form" onSubmit={onSubmitForm}>
                    <input
                        className="subscription__form--input form-text"
                        placeholder="Enter your email"
                        type="email"
                        onChange={onChangeInputHandler}
                        value={email}
                    />

                    <LoadingBtn
                        classNames="subscription__form--submit"
                        btnText="Subscribe"
                        isLoading={loading}
                    />
                </form>
                <div className={errorClassNames}>
                    You must have made a mistake. Please, enter a valid email
                    address!
                </div>
            </div>
        </div>
    );
}

export default PageSectionSubscription;
