import PageSectionSubscription from "../../components/page-section-subscription/PageSectionSubscription";
import PharmacyPartnersList from "../../components/pharmacy-partners-list/PharmacyPartnersList";

import "./pharmacies-page.scss";
import PharmaciesTable from "./pharmacies-table/PharmaciesTable";
export default function PharmaciesPage() {
    return (
        <div className="page-partners">
            <div className="container">
                <div className="row">
                    <div className="page-partners__content">
                        <h1 className="page-title">Our partners</h1>
                        <p className="subtitle">
                            Download your CharityRx card or coupons now and head
                            to any of our 70,000+ participating partners
                            nationwide including major drug chains and stores:
                        </p>
                        <div className="page-partners__content--list">
                            <PharmacyPartnersList />
                        </div>
                    </div>
                    <div className="page-partners__content--table">
                        <h2>Full list of pharmacies participating</h2>
                        <PharmaciesTable />
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <PageSectionSubscription />
                </div>
            </div>
        </div>
    );
}
