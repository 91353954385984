import SearchHighlight from "./images/highlights/search.svg";
import PriceHighlight from "./images/highlights/price.svg";
import MedicationsHighlight from "./images/highlights/medications.svg";
import AppDownloadBtns from "../../components/app-download/AppDownloadBtns";

import "./application-page.scss";

import ITEMS from "./items.json";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

const ReviewsItems = () => {
    const [emblaRef] = useEmblaCarousel({ loop: true }, [Autoplay()]);

    return (
        <div className="app-reviews__content">
            <div className="embla">
                <div className="embla__viewport" ref={emblaRef}>
                    <div className="embla__container">
                        {ITEMS.map((item, key) => (
                            <div key={key} className="review-item">
                                <div className="app-highlight__item--wrap">
                                    <div className="app-highlight__item--header">
                                        <span className="date">
                                            {item.location}
                                        </span>
                                        <span className="reviewer-name">
                                            {item.name}
                                        </span>
                                        <span className="rate">
                                            <span className="star" />
                                            <span className="star" />
                                            <span className="star" />
                                            <span className="star" />
                                            <span className="star" />
                                        </span>
                                    </div>
                                    <div className="app-highlight__item--content">
                                        <p>{item.content}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

function ApplicationPage() {
    return (
        <div className="page-application">
            <div className="container-fluid">
                <div className="row">
                    <div className="page-application__hero">
                        <div className="application-hero__title">
                            <h1>
                                Get exclusive discounts up to 95% on your
                                medications with the CharityRx app
                            </h1>
                            <p className="subtitle">
                                Accepted in 60,000+ pharmacies <br /> No
                                insurance required
                            </p>
                        </div>
                    </div>
                    <div className="page-application__downloadbtn">
                        <AppDownloadBtns />
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="page-application__highlights">
                        <div className="app-highlight__item">
                            <img src={SearchHighlight} className="img" />
                            <div className="app-highlight__item--content">
                                <h3>Compare prices</h3>
                                <p>
                                    Search and compare prices with exclusive
                                    CharityRx discounts on FDA approved
                                    medications in over 68000 U.S. pharmacies.
                                </p>
                            </div>
                        </div>
                        <div className="app-highlight__item">
                            <img src={MedicationsHighlight} className="img" />
                            <div className="app-highlight__item--content">
                                <h3>Free Coupons</h3>
                                <p>
                                    Show your CharityRX coupon to the any
                                    pharmacist and save up to 80% on your
                                    prescriptions.
                                </p>
                            </div>
                        </div>
                        <div className="app-highlight__item">
                            <img src={PriceHighlight} className="img" />
                            <div className="app-highlight__item--content">
                                <h3>Big Savings</h3>
                                <p>
                                    Download free drug coupons instantly on any
                                    device and begin saving immediately
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="page-application__reviews">
                        <div className="app-reviews__title">
                            <h2>App reviews</h2>
                            <p className="subtitle">
                                Real Reviews from Real Users: See How Our
                                Prescription Discounts Are Making a Difference!
                            </p>
                        </div>
                        <ReviewsItems />
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="page-application__download-app-now">
                        <h2>Download the CharityRx App for free!</h2>
                        <AppDownloadBtns />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApplicationPage;
