import "./drug-card-shimmer.scss";

function DrugCardShimmer() {
    return (
        <div className="component__drug-crad-shimmer">
            <div className="shimmer-wrapper drug-card__shimmer">
                <div className="drug-card__shimmer--content">
                    <div className="shimmer shimmer-title" />
                    <div className="shimmer shimmer-subtitle" />
                    <div className="drug-card__shimmer--desc">
                        <div className="shimmer shimmer-text" />
                        <div className="shimmer shimmer-text" />
                        <div className="shimmer shimmer-text" />
                        <div className="shimmer shimmer-text" />
                    </div>
                </div>
                <div className="drug-card__shimmer--img">
                    <div className="shimmer shimmer-img" />
                </div>
            </div>
        </div>
    );
}

export default DrugCardShimmer;
