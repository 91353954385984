import PropTypes from "prop-types";
import classnames from "classnames";
import { useEffect, useRef } from "react";
import "./modal-window.scss";

function ModalWindow({
    fullscreen,
    scrollable,
    children,
    title,
    className,
    closeHandler,
}) {
    const ModalRef = useRef(null);
    let scrollY = 0;

    const showModal = () => {
        // remember scroll position
        scrollY = window.scrollY;
        document.documentElement.classList.add("is-locked");
    };

    const hideModal = () => {
        document.documentElement.classList.remove("is-locked");
        // restore scroll position
        window.scrollTo(0, scrollY);
    };

    const preventDefault = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        // hack for stop scroll body.
        showModal();
        ModalRef.current.addEventListener("pointermove", preventDefault);
        return () => {
            hideModal();
            ModalRef.current?.removeEventListener(
                "pointermove",
                preventDefault
            );
        };
    }, []);

    const modalClasses = classnames({
        modal: true,
        "component__modal-window": true,
        [className]: className,
    });

    const modalDialogClasses = classnames({
        "modal-dialog": true,
        "modal-dialog-scrollable": scrollable,
        "modal-fullscreen": fullscreen,
    });
    return (
        <div
            ref={ModalRef}
            className={modalClasses}
            tabIndex="-1"
            aria-hidden="true"
        >
            <div className={modalDialogClasses}>
                <div className="modal-content">
                    {title ? (
                        <div className="modal-title">
                            <div className="block">
                                <h3>{title}</h3>
                            </div>
                            <div className="block">
                                <span
                                    className="close-icon"
                                    onClick={() => closeHandler()}
                                />
                            </div>
                        </div>
                    ) : null}
                    {children ? (
                        <div className="modal-body">{children}</div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

ModalWindow.propTypes = {
    fullscreen: PropTypes.bool,
    scrollable: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.any,
    title: PropTypes.string,
    closeHandler: PropTypes.func.isRequired,
};

ModalWindow.defaultProps = {
    fullscreen: false,
    scrollable: false,
};

export default ModalWindow;
