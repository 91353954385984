import questionsItems from "./questions.json";

import QuestionForm from "./question-form/QuestionForm";
import FaqQuestionItem from "../../components/faq-question-item/FaqQuestionItem";
import PageSectionSubscription from "../../components/page-section-subscription/PageSectionSubscription";

import "./faq-page.scss";

export default function FaqPage() {
    return (
        <div className="page-faq">
            <div className="container">
                <div className="row">
                    <h1 className="page-title">Frequently Asked Questions</h1>
                    <div className="page-faq__questions">
                        <div className="page-faq__questions--wrap thin-custom-row">
                            <div className="faq-questions">
                                {questionsItems.map((item, key) => (
                                    <FaqQuestionItem key={key} item={item} />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="page-faq__form">
                        <h2>Have more questions about CharityRx?</h2>
                        <p className="subtitle">
                            Please fill out the form below and one of our team
                            members will get back to you shortly.
                        </p>
                        <QuestionForm />
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <PageSectionSubscription />
                </div>
            </div>
        </div>
    );
}
