export default class Pharmacy {
    id = null;
    baseName = null;
    fullName = null;

    drug = null;

    loading = true;

    constructor(json) {
        this.init(json);
    }

    get ndc11() {
        return this.drug.ndcs[0];
    }

    init(json) {
        this.id = json.pharmacyId;
        this.drug = {
            ndcs: json.ndcs,
            unit: json.unit,
        };

        this.baseName = json.pharmacyBaseName;
        this.fullName = json.pharmacyName;
        this.price = json.total;
    }
}
