import { useContext } from "react";
import LocationDropdown from "../../../components/dropdown/LocationDropdown";
import SearchResultMap from "../search-result-map/SearchResultMap";

import SearchList from "./SearchList";
import "./search-result.scss";
import { observer } from "mobx-react-lite";
import { SearchContext } from "../../../stores/contexts";

const SortActions = observer(() => {
    // true - lowest // false - nearest
    const { resultStore } = useContext(SearchContext);
    const { sort } = resultStore;

    const onClickHandler = (state) => {
        resultStore.switchSort(state);
    };

    return (
        <div className="component__sort-actions">
            <div className="action-item" onClick={() => onClickHandler(true)}>
                <span className={`checkbox ${sort === true ? "active" : ""}`} />
                <p>Lowest prices</p>
            </div>
            <div className="action-item" onClick={() => onClickHandler(false)}>
                <span
                    className={`checkbox ${sort === false ? "active" : ""}`}
                />
                <p>Nearest pharmacies</p>
            </div>
        </div>
    );
});

const ComponentTitle = observer(() => {
    const { resultStore } = useContext(SearchContext);
    const { count } = resultStore;

    return (
        <h2>
            Pharmacies Near Me{" "}
            {count > 1 ? (
                <span className="count">({count} results)</span>
            ) : null}
        </h2>
    );
});

function SearchResult() {
    return (
        <div className="component__search-result">
            <div className="search-result__main">
                <ComponentTitle />
                <div className="search-result__main--settings">
                    <div className="search__result-location">
                        <LocationDropdown />
                    </div>
                    <div className="search__result-sorts">
                        <SortActions />
                    </div>
                </div>
                <div className="search-result__main--list">
                    <SearchList />
                </div>
            </div>
            <div className="search-result__map">
                <SearchResultMap />
            </div>
        </div>
    );
}

export default SearchResult;
