import { NavLink } from "react-router-dom";
import "./section-questions.scss";

import QuestionsImage from "./images/questions.png";

function SectionQuestions() {
    return (
        <div className="section__questions thin-custom-row">
            <div className="section__questions--content">
                <h2>Got questions?</h2>
                <p>
                    Our <NavLink to="/faq">FAQ page</NavLink> covers all common
                    queries about how the CharityRx discount program works. You
                    may also contact our support team for extra assistance
                    anytime.
                </p>
                <NavLink to="/faq">
                    <button className="button purple">Find answers</button>
                </NavLink>
            </div>
            <div className="section__questions--img">
                <img
                    src={QuestionsImage}
                    style={{ aspectRatio: 451 / 383 }}
                    alt="CharityRx Questions"
                />
                <NavLink to="/faq">
                    <button className="button purple">Find answers</button>
                </NavLink>
            </div>
        </div>
    );
}

export default SectionQuestions;
