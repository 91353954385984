import { orderBy } from "lodash";
import { getUserLocation } from "../stores/location.store";
import { DoAutocompleteRequest } from "./api/api.autocomplete";
import {
    DoGetDrugDescriptionRequest,
    DoGetDrugFiltersRequest,
    DoGetDrugSaveUpRequest,
    DoGetPackagesRequest,
    getSampleSavingRequest,
} from "./api/api.drugs";
import { DoGetDrugInfoForCoupon } from "./api/api.info";
import {
    DoGetPharmaciesByLocation,
    DoGetMainSearch,
    DoGetPriceByPharmacy,
    DoGetPreferPharmaciesSearch,
} from "./api/api.search";

export const getSampleSavingDrugs = async () => {
    // const location = getUserLocation();
    // const result = await getSampleSavingRequest(location.zip);
    const result = await getSampleSavingRequest("33141"); // always return by this zipcode

    if (result) {
        return result;
    } else {
        return null;
    }
};

export const getDrugInfoForCoupon = async (params) => {
    const result = await DoGetDrugInfoForCoupon(params);
    if (result !== null) {
        return result;
    } else {
        return [];
    }
};

export const getSearchAutocomplete = async (searchStr, callback) => {
    const result = await DoAutocompleteRequest(searchStr);
    if (result !== null) {
        return callback(orderBy(result, "type", "desc"));
    } else {
        return [];
    }
};

export const getDrugDescription = async (name) => {
    const result = await DoGetDrugDescriptionRequest(name.replaceAll("-", " "));
    if (result !== null) {
        return result;
    } else {
        return null;
    }
};

export const getDrugSaveUp = async (name) => {
    const result = await DoGetDrugSaveUpRequest(name.replaceAll("-", " "));
    if (result !== null) {
        return result;
    } else {
        return null;
    }
};

export const getDrugPrescription = async (name) => {
    const result = await DoGetDrugFiltersRequest(name.replaceAll("-", " "));
    if (result !== null) {
        return result;
    } else {
        return [];
    }
};

export const getPackagesByNdcs = async (ndcs) => {
    const result = await DoGetPackagesRequest(ndcs.toString());
    return result;
};

export const getPharmaciesByLocation = async () => {
    const userLocation = getUserLocation();
    const result = await DoGetPharmaciesByLocation({
        ...userLocation.addrObject,
    });
    if (result !== null) {
        return result;
    } else {
        return [];
    }
};

export const getPriceByPharmacyId = async (
    { ndcs, unit },
    pharmacyId,
    pharmacyName
) => {
    const location = getUserLocation();
    const result = await DoGetPriceByPharmacy({
        id: pharmacyId,
        name: pharmacyName,
        ndcs,
        unit,
        zip: location.zip,
    });
    if (result !== null) {
        return result;
    } else {
        return null;
    }
};

export const getPreferPharmaciesResult = async (params) => {
    const userLocation = getUserLocation();
    const result = await DoGetPreferPharmaciesSearch({
        ...params,
        location: userLocation.addrObject,
    });
    if (result !== null) {
        return result;
    } else {
        return [];
    }
};

export const getMainSearchResult = async (params) => {
    const userLocation = getUserLocation();
    const result = await DoGetMainSearch({
        ...params,
        location: userLocation.addrObject,
    });
    return result;
};
