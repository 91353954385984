import { Title, Description, List, ListItem } from "./blocks/Blocks";

import "./information-page.scss";

function PrivacyPolicyPage() {
    return (
        <div className="information-page">
            <div className="container">
                <div className="row">
                    <div className="information-page__header">
                        <h1>Privacy policy</h1>
                        <p className="subtitle">
                            Privacy Policy for CharityRx free coupon program
                        </p>
                    </div>
                    <div className="information-page__content">
                        <Description text="This privacy policy has been compiled to better serve those who are concerned with how their 'Personally identifiable information' (PII) is being used online. PII, as used in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website." />

                        <Title text="What personal information do we collect from the people that visit our blog, website or app?" />
                        <Description text="When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, mailing address, phone number or other details to help you with your experience." />

                        <Title text="When do we collect information?" />
                        <Description text="We collect information from you when you register on our site, fill out a form or enter information on our site." />

                        <Title text="How do we use your information?" />
                        <Description text="We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:" />

                        <List>
                            <ListItem text="To personalize user's experience and to allow us to deliver the type of content and product offerings in which you are most interested." />
                            <ListItem text="To improve our website in order to better serve you." />
                            <ListItem text="To allow us to better serve you in reponding to your customer service requests." />
                            <ListItem text="To administer a contest, promotion, survey or other site feature." />
                            <ListItem text="To quickly process your transactions." />
                            <ListItem text="To send periodic emails regarding your order or other products and services." />
                        </List>

                        <Title text="How do we protect visitor information?" />
                        <Description text="We do not use vulnerability scanning and/or scanning to PCI standards. Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are requiredto keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology." />
                        <Description text="We implement a variety of security measures when a user places an order to maintain the safety of your personal information." />
                        <Description text="All transactions are processed through a gateway provider and are not stored or processed on our servers." />

                        <Title text="Do we use 'cookies'?" />
                        <Description text="We do not use cookies for tracking purposes. You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser (like Internet Explorer) settings. Each browser is a little different, so look at your browser's Help menu to learn the correct way to modify your cookies." />
                        <Description text="If you disable cookies off, some features will be disabled that make your site experience more efficient and some of our services will not function properly." />
                        <Description text="However, you can still place orders." />

                        <Title text="Third Party Disclosure" />
                        <Description text="We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide you with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety." />
                        <Description text="However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses." />

                        <Title text="Third party links" />
                        <Description text="Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacypolicies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites." />

                        <Title text="Google" />
                        <Description
                            text="Google's advertising requirements can be summed up by Google's Advertising Principles. They are put in place to provide a positive experience for users."
                            link="https://support.google.com/adwordspolicy/answer/1316548?hl=en"
                        />
                        <Description text="We have not enabled Google AdSense on our site but we may do so in the future." />

                        <Title text="California Online Privacy Protection Act" />
                        <Description
                            text={
                                "CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law's reach stretches well beyond California to require a person or company in the United States (and conceivably the world) that operates websites collecting personally identifiable information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals with whom it is being shared, and to comply with this policy.\n- See more at:"
                            }
                            link="http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf"
                        />

                        <Description
                            text={
                                "According to CalOPPA we agree to the following:\nUsers can visit our site anonymously. Once this privacy policy is created, we will add a link to it on our home page, or as a minimum on the first significant page afterentering our website. Our Privacy Policy link includes the word 'Privacy', and can be easily be found on the page specified above."
                            }
                        />
                        <Description
                            text={
                                "Users will be notified of any privacy policy changes:\nOn our Privacy Policy Page"
                            }
                        />
                        <Description
                            text={
                                "Users are able to change their personal information:\nBy calling us\nBy logging in to their account"
                            }
                        />

                        <Title text="How does our site handle do not track signals?" />
                        <Description text="We honor do not track signals and do not track, plant cookies, or use advertising when a Do Not Track (DNT)browser mechanism is in place." />

                        <Title text="Does our site allow third party behavioral tracking?" />
                        <Description text="It's also important to note that we do not allow third party behavioral tracking." />

                        <Title text="COPPA (Children Online Privacy Protection Act)" />
                        <Description text="When it comes to the collection of personal information from children under 13, the Children's Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, the nation's consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children's privacy and safety online. We do not specifically market to children under 13." />

                        <Title text="Fair Information Practices" />
                        <Description text="The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information." />
                        <Description
                            text={
                                "In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:\nWe will notify the users via email within 7 business days.\nWe also agree to the individual redress principle, whichrequires that individuals have a right to pursue legally enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or a government agency to investigate and/or prosecute non-compliance by data processors."
                            }
                        />

                        <Title text="CAN SPAM Act" />
                        <Description text="The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations." />
                        <Description
                            text={
                                "We collect your email address in order to:\nSend information, respond to inquiries, and/or other requests or questions. Process orders and to send information and updates pertaining to orders We may also send you additional information related to your product and/or service. Market to our mailing list or continue to send emails to our clients after the original transaction has occurred."
                            }
                        />
                        <Description text="To be accordance with CANSPAM we agree to the following:" />

                        <List>
                            <ListItem text="NOT use false, or misleading subjects or email addresses." />
                            <ListItem text="Indentify the message as an advertisement insome reasonable way." />
                            <ListItem text="Include the physical address of our business or site headquarters." />
                            <ListItem text="Monitor third party email marketing services for compliance, if one is used. Honor opt-out/unsubscrive requests quickly." />
                            <ListItem text="Allow users to unsubscribe by using the link at the bottom of each email. If at any time you would like to unsubscribe from receiving future emails, you can." />
                        </List>

                        <Description text="Follow the instructions at the bottom of each email." />

                        <Title text="Contacting Us" />
                        <Description text="If there are any questions regarding this privacy policy you may contact us using the information below." />
                        <Description
                            text={
                                "Charity Rx, LLC\n78 SW 7TH St, Suite 500\n8th FL, South Tower"
                            }
                        />
                        <Description
                            disableMarginBottom
                            text={"Miami, FL 33131\nUSA"}
                        />
                        <a href="mailto:help@charityrx.com">
                            support@CharityRx.com
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicyPage;
