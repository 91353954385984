import {
    DoDownloadCardRequest,
    DoSendCardToEmailRequest,
    DoSendCardToPhoneRequest,
} from "./api/api.card";

const downloadBlog = (data, filename, mime, bom) => {
    let blobData = typeof bom !== "undefined" ? [bom, data] : [data];
    let blob = new Blob(blobData, { type: mime || "application/octet-stream" });
    if (typeof window.navigator.msSaveBlob !== "undefined") {
        // IE workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        window.navigator.msSaveBlob(blob, filename);
    } else {
        let blobURL =
            window.URL && window.URL.createObjectURL
                ? window.URL.createObjectURL(blob)
                : window.webkitURL.createObjectURL(blob);
        let tempLink = document.createElement("a");
        tempLink.style.display = "none";
        tempLink.href = blobURL;
        tempLink.setAttribute("download", filename);

        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
        }

        document.body.appendChild(tempLink);
        tempLink.click();

        // Fixes "webkit blob resource error 1"
        setTimeout(function () {
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
        }, 200);
    }
};

export const downloadRxCard = async () => {
    try {
        const response = await DoDownloadCardRequest();
        if (response.status === 200) {
            const { data } = response;
            downloadBlog(data, "CharityRx_card.pdf", data.type);
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
};

export const printRxCard = async () => {
    try {
        const data = await DoDownloadCardRequest();
        if (data !== null) {
            window.open(URL.createObjectURL(data), "_blank");
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
};

export const sendToEmailRxCard = async (card, email) => {
    try {
        const result = await DoSendCardToEmailRequest(card, email);
        if (result !== null) {
            return result.success;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
};

export const sendToSMSRxCard = async (card, phoneNumber) => {
    try {
        let formattedNumber = phoneNumber.replaceAll(/[^\d]/g, "");

        if (formattedNumber.length === 10) {
            formattedNumber = "+1" + formattedNumber;
        }
        const result = await DoSendCardToPhoneRequest(card, formattedNumber);
        if (result !== null) {
            return result.success;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
};
