import PropTypes from "prop-types";
import classnames from "classnames";

import "./navigation.scss";

function MobileHamburger({ isOpen, click }) {
    const hamburgerClasses = classnames({
        component__hamburger: true,
        "component__hamburger--open": isOpen,
    });

    return (
        <div className={hamburgerClasses} onClick={() => click(!isOpen)}>
            <span />
            <span />
            <span />
        </div>
    );
}

MobileHamburger.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    click: PropTypes.func.isRequired,
};

export default MobileHamburger;
