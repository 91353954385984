import { any, string, bool } from "prop-types";

import "../information-page.scss";

export const Title = ({ text }) => (
    <h3 className="information-page__title">{text}</h3>
);

Title.propTypes = {
    text: string,
};

export const Description = ({ text, link, bold }) => (
    <>
        <p
            className={`information-page__desc ${
                bold ? "information-page__desc--bold" : ""
            }`}
            dangerouslySetInnerHTML={{ __html: text }}
        />
        {link && <a href={link}>{` ${link}`}</a>}
    </>
);

Description.propTypes = {
    text: string.isRequired,
    link: string,
    disableMarginBottom: bool,
    bold: bool,
};

export const List = ({ children }) => (
    <ul className="information-page__list">{children}</ul>
);

List.propTypes = {
    children: any,
};

export const ListItem = ({ text }) => (
    <li
        className="information-page__list--item"
        dangerouslySetInnerHTML={{ __html: text }}
    />
);

ListItem.propTypes = {
    text: string.isRequired,
};
