import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";
import { Fragment, useContext, useEffect, useState } from "react";
import { SearchContext } from "../../../../stores/contexts";

import MobileDrugModal from "./MobileDrugModal";

import "./mobile-drug-prescription.scss";

const CurrentFilterName = observer(() => {
    const store = useContext(SearchContext);
    return (
        <div className="mobile-prescription__current">
            <span>{store.getCurrentPrescriptionStr()}</span>
        </div>
    );
});

function MobileDrugPrescription({ drug }) {
    const [showEditModal, setEditModalState] = useState(false);
    const { filtersStore } = useContext(SearchContext);

    useEffect(() => {
        filtersStore.init(drug);
    }, [drug]);

    return (
        <Fragment>
            <div className="component__mobile-prescription">
                <CurrentFilterName />
                <div className="mobile-prescription__edit">
                    <button
                        className="button edit-btn"
                        onClick={() => setEditModalState(true)}
                    >
                        Edit
                    </button>
                </div>
            </div>
            {showEditModal && (
                <MobileDrugModal
                    closeHandler={() => setEditModalState(false)}
                />
            )}
        </Fragment>
    );
}

MobileDrugPrescription.propTypes = {
    drug: PropTypes.string.isRequired,
};

export default MobileDrugPrescription;
