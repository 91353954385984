import { useState } from "react";

import PropTypes from "prop-types";
import classnames from "classnames";

import DefaultImage from "./images/default.png";

const ReadMoreBtn = ({ expand, onExpandClick }) => {
    const text = expand ? "Read less" : "Read more";
    const clasess = classnames({
        "button read-more": true,
        expanded: expand,
    });

    return (
        <button className={clasess} onClick={onExpandClick}>
            {text}
        </button>
    );
};

ReadMoreBtn.propTypes = {
    expand: PropTypes.bool.isRequired,
    onExpandClick: PropTypes.func.isRequired,
};

function DrugDescription({ drugInfo }) {
    const [isExpand, setExpand] = useState(false);

    const renderTextArticle = (title = "", text) => {
        if (text && text != "") {
            return (
                <div className="article-item">
                    {title != "" && (
                        <h4 className="article-item--title">{title}</h4>
                    )}
                    <p
                        className="article-item--text"
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                </div>
            );
        } else {
            return null;
        }
    };

    const renderListArticle = (title, list = [], withColor = false) => {
        if (list.length > 0) {
            let items = list.map((item, key) => (
                <li key={key}>
                    <span
                        className={withColor ? "color" : ""}
                        dangerouslySetInnerHTML={{ __html: item }}
                    />
                </li>
            ));

            return (
                <div className="article-list-item">
                    {title != "" && (
                        <h4 className="article-list-item--title">{title}</h4>
                    )}
                    <ul className="article-list-item--list">{items}</ul>
                </div>
            );
        }
    };

    const renderImage = (imgArr) => {
        if (imgArr && imgArr.length > 0) {
            return <img src={imgArr[0]} alt="CharityRx" />;
        } else {
            return <img src={DefaultImage} alt="CharityRx" />;
        }
    };

    const onClickReadMoreBtn = () => {
        setExpand(!isExpand);
    };

    const fullDescClassNames = classnames({
        drugdescription__content: true,
        expand: isExpand,
    });

    const sidebarClassNames = classnames({
        "component__drugdescription--sidebar": true,
        expand: isExpand,
    });

    return (
        <div className="component__drugdescription">
            <div className="component__drugdescription--main">
                <div className={fullDescClassNames}>
                    <p className="excerpt">{drugInfo?.excerpt}</p>
                    {drugInfo?.description &&
                        renderTextArticle("", drugInfo.description)}
                    {/* {drugInfo?.pharmacology?.description &&
                        renderTextArticle(
                            "Pharmacodynamics",
                            drugInfo.pharmacology.description
                        )}
                    {drugInfo?.pharmacology?.indication &&
                        renderTextArticle(
                            "Pharmacology Indication",
                            drugInfo.pharmacology.indication
                        )}
                    {drugInfo?.foodInteractions &&
                        renderTextArticle(
                            "Food interactions",
                            drugInfo.foodInteractions
                        )} */}
                </div>
                <ReadMoreBtn
                    expand={isExpand}
                    onExpandClick={onClickReadMoreBtn}
                />
            </div>
            <div className={sidebarClassNames}>
                <div className="drugdescription__sidebar--img">
                    {renderImage(drugInfo.images)}
                </div>
                <div className="drugdescription__sidebar--content">
                    {drugInfo?.indications &&
                        renderListArticle(
                            "Conditions",
                            drugInfo.indications,
                            true
                        )}
                    {drugInfo?.therapeuticCategories &&
                        renderListArticle(
                            "Categories",
                            drugInfo.therapeuticCategories
                        )}
                </div>
            </div>
        </div>
    );
}

DrugDescription.propTypes = {
    drugInfo: PropTypes.object,
};

export default DrugDescription;
