import PropTypes from "prop-types";
import classnames from "classnames";
import { useState } from "react";
import QRCode from "react-qr-code";
import ReactInputMask from "react-input-mask";

import {
    sendToEmailRxCard,
    sendToSMSRxCard,
} from "../../../services/card.service";

import ModalSmallWindow from "../../modal-window/ModalSmallWindow";
import LoadingBtn from "../../loading-btn/LoadingBtn";

import "./card-rx-modals.scss";

const QRCodeModal = ({ closeHandler }) => {
    const getQrCodeLink = () => {
        return window.location.href;
    };

    return (
        <ModalSmallWindow
            className="modal__qr-code"
            closeHandler={() => closeHandler()}
        >
            <div className="qr-code__content">
                <QRCode value={getQrCodeLink()} />
                <h3>Scan Or Share this code</h3>
            </div>
            <div className="modal-small__bottom qr-code__bottom">
                <button
                    className="button purple"
                    onClick={() => closeHandler()}
                >
                    Done
                </button>
            </div>
        </ModalSmallWindow>
    );
};

const TextModal = ({ cardName, title, closeHandler }) => {
    const [value, setValue] = useState("");
    const [agree, setAgree] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");
    const [loading, switchLoading] = useState(false);

    const onChangeHandler = (e) => {
        let _value = e.target.value;
        setValue(_value);
    };

    const onSendHandler = async (e) => {
        e.preventDefault();
        if (!agree) {
            setError("Please agree with conditions.");
            return false;
        }

        setSuccess(false);
        setError("");
        switchLoading(true);
        const result = await sendToSMSRxCard(cardName, value);
        switchLoading(false);
        if (result) {
            setSuccess(true);
            setValue("");
        } else {
            setError(
                "You have a error, please check your number and try again."
            );
        }
    };

    const formClassNames = classnames({
        "text__content--form": true,
        error: error !== "",
    });

    return (
        <ModalSmallWindow
            className="modal__sms"
            closeHandler={() => closeHandler()}
            successMsg="Your will receive your CharityRx Card to your phone within a few minutes."
            showSuccessMsg={success}
        >
            <div className="text__content">
                <h3>{title ? title : "Receive a card via Text"}</h3>
                <div className={formClassNames}>
                    <ReactInputMask
                        mask="(999) 999-9999"
                        type="text"
                        className="input form-text"
                        placeholder="Enter your phone"
                        value={value}
                        onChange={onChangeHandler}
                    />
                    <p className="notice-error">{error}</p>
                </div>
                <div className="text__content-agree">
                    <label className="text__content-agree-label">
                        <input
                            className="text__content-agree-input"
                            type="checkbox"
                            value={agree}
                            onChange={() => setAgree((prev) => !prev)}
                        />
                        <span className="text__content-agree-desc">
                            I agree to receive SMS from CharityRx, LLC
                        </span>
                    </label>
                </div>
            </div>
            <div className="modal-small__bottom text__bottom">
                <button
                    className="button cancel-btn"
                    onClick={() => closeHandler()}
                >
                    Cancel
                </button>
                <LoadingBtn
                    isLoading={loading}
                    click={onSendHandler}
                    btnText="Confirm"
                />
            </div>
            <div className="text__bottom-notice">
                <p className="text__bottom-notice-text">
                    By confirming, you agree to receive marketing text message
                    at the phone number provided. Reply STOP to cancel. Msg
                    rates may apply.
                </p>
            </div>
        </ModalSmallWindow>
    );
};

const EmailModal = ({ cardName, title, closeHandler }) => {
    const [value, setValue] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, switchLoading] = useState(false);

    const onChangeHandler = (e) => {
        let _value = e.target.value;
        setValue(_value);
    };

    const onSendHandler = async (e) => {
        e.preventDefault();
        setSuccess(false);
        setError(false);
        switchLoading(true);
        const result = await sendToEmailRxCard(cardName, value);
        switchLoading(false);
        if (result) {
            setSuccess(true);
            setValue("");
        } else {
            setError(true);
        }
    };

    const formClassNames = classnames({
        "text__content--form": true,
        error: error,
    });

    return (
        <ModalSmallWindow
            className="modal__email"
            closeHandler={() => closeHandler()}
            successMsg="Your will receive your CharityRx Card on your Email address within a few minutes."
            showSuccessMsg={success}
        >
            <div className="text__content">
                <h3>{title ? title : "Receive a card via email"}</h3>
                <div className={formClassNames}>
                    <input
                        type="text"
                        className="input form-text"
                        placeholder="Enter your e-mail"
                        value={value}
                        onChange={onChangeHandler}
                    />
                    <p className="notice-error">
                        You have a error, please check your email and try again.
                    </p>
                </div>
            </div>
            <div className="modal-small__bottom text__bottom">
                <button
                    className="button cancel-btn"
                    onClick={() => closeHandler()}
                >
                    Cancel
                </button>
                <LoadingBtn
                    isLoading={loading}
                    click={onSendHandler}
                    btnText="Confirm"
                />
            </div>
        </ModalSmallWindow>
    );
};

TextModal.propTypes = {
    closeHandler: PropTypes.func,
    title: PropTypes.string,
    cardName: PropTypes.string,
};

EmailModal.propTypes = {
    closeHandler: PropTypes.func,
    title: PropTypes.string,
    cardName: PropTypes.string,
};

QRCodeModal.propTypes = {
    closeHandler: PropTypes.func,
};

export { QRCodeModal, TextModal, EmailModal };
