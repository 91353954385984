import PropTypes from "prop-types";
import classnames from "classnames";
import GoogleMap from "google-map-react";
import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { find } from "lodash";
// import Spinner from "../../../components/spinner/Spinner";
import { SearchContext } from "../../../stores/contexts";
import MapMarker from "./map-marker/MapMarker";

import "./search-result-map.scss";
import MapMarkerInfoWindow from "./map-marker/MapMarkerInfoWindow";

const MAP_ENV = process.env.REACT_APP_GOOGLE_MAP_KEY
    ? process.env.REACT_APP_GOOGLE_MAP_KEY
    : "";

const MAP_ZOOM = 10;
const MAP_DEFAULT_LOCATION = [34.0522, -118.2437];

// Return map bounds based on list of places
const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds();

    places.forEach((place) => {
        bounds.extend(
            new maps.LatLng(
                place.pharmacyCoords.latitude,
                place.pharmacyCoords.longitude
            )
        );
    });
    return bounds;
};

// Re-center map when resizing the window
const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, "idle", () => {
        maps.event.addDomListener(window, "resize", () => {
            map.fitBounds(bounds);
        });
    });
};

// Fit map to its bounds after the api is loaded
const apiIsLoaded = (map, maps, places) => {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps, places);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
};

function SearchResultMap({ isMobile }) {
    const [mobileInfoWindowItem, setMobileInfoWindowItem] = useState(null);
    const { resultStore } = useContext(SearchContext);
    const { itemsOnMap } = resultStore;

    const onChildHoverHandler = (key, hover) => {
        let currentItem = find(itemsOnMap, ["id", key]);
        if (currentItem) {
            currentItem.switchHover(hover);
        }
    };

    const onChildClickHandler = (key) => {
        setMobileInfoWindowItem(null);
        let currentItem = find(itemsOnMap, ["id", key]);
        setMobileInfoWindowItem(currentItem);
    };

    function createMapOptions() {
        return {
            styles: [
                {
                    featureType: "poi",
                    elementType: "labels",
                    stylers: [{ visibility: "off" }],
                },
            ],
        };
    }

    const blockClassNames = classnames({
        "component__result-map": true,
        "component__result-map--loading": itemsOnMap.length === 0,
    });

    return (
        <div className={blockClassNames}>
            {itemsOnMap.length > 0 ? (
                <GoogleMap
                    options={createMapOptions}
                    bootstrapURLKeys={{ key: MAP_ENV }}
                    defaultCenter={MAP_DEFAULT_LOCATION} //LA Center
                    defaultZoom={MAP_ZOOM}
                    onChildMouseEnter={(key) => onChildHoverHandler(key, true)}
                    onChildMouseLeave={(key) => onChildHoverHandler(key, false)}
                    onChildClick={onChildClickHandler}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) =>
                        apiIsLoaded(map, maps, itemsOnMap)
                    }
                >
                    {itemsOnMap.map((item) => (
                        <MapMarker
                            key={item.id}
                            lat={item.pharmacyCoords.latitude}
                            lng={item.pharmacyCoords.longitude}
                            hover={item.hover}
                        />
                    ))}
                </GoogleMap>
            ) : null}
            {isMobile && mobileInfoWindowItem ? (
                <MapMarkerInfoWindow
                    item={mobileInfoWindowItem}
                    onCloseHandler={() => setMobileInfoWindowItem(null)}
                />
            ) : null}
        </div>
    );
}

SearchResultMap.propTypes = {
    isMobile: PropTypes.bool.isRequired,
};

SearchResultMap.defaultProps = {
    isMobile: false,
};

export default observer(SearchResultMap);
