import FiltersStore from "./filters.store";
import ResultStore from "./result.store";

import * as SearchService from "../../services/search.service";
import PackagesStore from "./packages.store";

export default class SearchRootStore {
    filtersStore;
    packagesStore;
    resultStore;

    constructor() {
        this.filtersStore = new FiltersStore(this);
        this.packagesStore = new PackagesStore(this);
        this.resultStore = new ResultStore(this);

        this.service = SearchService;
    }

    getCurrentPrescriptionStr() {
        let route = this.filtersStore.route;
        let form = this.filtersStore.form;
        let strength = this.filtersStore.strength;
        let selectedPack = this.packagesStore.selected;
        let selectedCustomUnitPack = this.packagesStore.customUnit;

        if (form && strength && selectedPack) {
            let packUnitLabel = selectedPack.label;
            if (packUnitLabel && selectedCustomUnitPack !== null) {
                packUnitLabel = packUnitLabel.replace(
                    /[\d]+/gm,
                    selectedCustomUnitPack
                );
            }

            return `${route?.label}, ${form?.label}, ${strength?.label}  ${packUnitLabel}`;
        } else {
            return "";
        }
    }
}
