import PropTypes from "prop-types";

function ContentRxIcon({ className, imageAlt, imageName }) {
    return (
        <img
            className={`component__content_rx_icon ${className}`}
            alt={imageAlt}
            src={require(`./images/${imageName}.svg`)}
        />
    );
}

ContentRxIcon.propTypes = {
    className: PropTypes.string,
    imageAlt: PropTypes.string,
    imageName: PropTypes.oneOf([
        "accepted",
        "free",
        "heart",
        "price",
        "restrictions",
        "confidential",
        "medications",
    ]),
};
ContentRxIcon.defaultProps = {
    imageAlt: "CharityRx icon",
};

export default ContentRxIcon;
