import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const AVAILABLE_PHARMA = [
    "kroger",
    "publix",
    "walmart",
    "cvs",
    "walgreens",
    "target",
    "rite_aid",
    "rite-aid",
];

function GetACouponBtn({ title, pharmacyName }) {
    const { drug: drugSlugName } = useParams();
    const [link, setLink] = useState("/coupon");

    useEffect(() => {
        if (AVAILABLE_PHARMA.includes(pharmacyName)) {
            if (pharmacyName === "rite_aid") {
                // temp fix?
                setLink("/rite-aid");
            } else {
                setLink(`/${pharmacyName}`);
            }
        } else if (drugSlugName) {
            setLink(`/coupon/${drugSlugName}`);
        }
    }, [drugSlugName, pharmacyName]);

    return (
        <Link to={link}>
            <button className="button purple get-a-card-btn">
                {title ? title : "Get Coupon"}
            </button>
        </Link>
    );
}

GetACouponBtn.propTypes = {
    title: PropTypes.string,
    pharmacyName: PropTypes.string,
    ndc: PropTypes.string,
};

export default GetACouponBtn;
