import { Link } from "react-router-dom";

import AppStoreImage3x from "./images/png/app-store-btn-3x.png";
import GooglePlayImage3x from "./images/png/google-play-btn-3x.png";

import "./app-download.scss";

function AppDownloadBtns() {
    return (
        <div className="component__app-download-btns">
            <div className="component__app-download-btns--wrap">
                <Link
                    className="component__app-download-link"
                    target="__blank"
                    to="https://apps.apple.com/us/app/charityrx-best-free-rx-coupons/id1567879209"
                >
                    <img src={AppStoreImage3x} />
                </Link>
                <Link
                    className="component__app-download-link"
                    target="__blank"
                    to="https://play.google.com/store/apps/details?id=com.charityrxapp"
                >
                    <img src={GooglePlayImage3x} />
                </Link>
            </div>
        </div>
    );
}

export default AppDownloadBtns;
