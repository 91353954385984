import PropTypes from "prop-types";
import GetACouponBtn from "../../../../components/get-a-coupon-btn/GetACouponBtn";

import "./map-marker.scss";

function MapMarkerInfoWindow({ item, onCloseHandler }) {
    return (
        <div className="component__mapmarker-infowindow">
            <div className="infowindow__close">
                <span className="close-icon" onClick={onCloseHandler} />
            </div>
            <div className="block">
                <div className="infowindow__content">
                    <span className="infowindow__content--name">
                        {item?.pharmacyName}
                    </span>
                    <span className="infowindow__content--address">
                        {item?.pharmacyAddress}
                    </span>
                    <span className="infowindow__content--time">
                        {item?.pharmacyOpenHours}
                    </span>
                </div>
                <div className="infowindow__price">
                    <span>${item?.price}</span>
                </div>
            </div>

            <div className="infowindow__btn">
                <GetACouponBtn title="Get Coupon" ndc={item?.ndc11} />
            </div>
        </div>
    );
}

MapMarkerInfoWindow.propTypes = {
    item: PropTypes.object,
    onCloseHandler: PropTypes.func,
};

export default MapMarkerInfoWindow;
