import PropTypes from "prop-types";

import { debounce } from "lodash";
import { Fragment, useMemo, useState, useEffect } from "react";
import { getSearchAutocomplete } from "../../services/search.service";
import "./search-bar.scss";

function SearchInput({
    searchValue,
    onChangeSearchValue,
    placeholder,
    onLoading,
    onSearch,
    onResults,
}) {
    const [clear, setClear] = useState(false);
    const search = (str) => {
        onLoading(true);
        getSearchAutocomplete(str, (items) => {
            onResults(items);
            onLoading(false);
        });
    };
    const debounceSearchHandler = useMemo(() => debounce(search, 300), []);

    const onChangeHandler = (e) => {
        const str = e.target.value;
        onChangeSearchValue(str);
        if (str !== undefined && str.length >= 3) {
            setClear(true);
            onSearch(true);
            debounceSearchHandler(str);
        } else {
            onSearch(false);
            setClear(false);
        }
    };

    const onClearHandler = () => {
        onChangeSearchValue("");
        setClear(false);
        onSearch(false);
    };

    // Stop the invocation of the debounced function
    // after unmounting
    useEffect(() => {
        return () => {
            debounceSearchHandler.cancel();
        };
    }, []);

    return (
        <Fragment>
            <input
                type="text"
                className="form-text searchbar__form--input"
                placeholder={placeholder}
                value={searchValue}
                onChange={onChangeHandler}
            />
            <span
                onClick={onClearHandler}
                className={`searchbar__form--clear ${clear ? "show" : ""}`}
            />
        </Fragment>
    );
}

SearchInput.propTypes = {
    searchValue: PropTypes.string.isRequired,
    onChangeSearchValue: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    onLoading: PropTypes.func.isRequired,
    onResults: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
};

export default SearchInput;
