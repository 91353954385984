import { observer } from "mobx-react-lite";
import { find } from "lodash";
import { Fragment, useContext } from "react";
import { SearchContext } from "../../../stores/contexts";

import MainResultItem from "./main-result-item/MainResultItem";
import PreferResultItem from "./prefer-result-item/PreferResultItem";

import ListItemShimmer from "./list-item-shimmer/ListItemShimmer";
import TableError from "../../../components/errors/TableError";

import "./search-result.scss";

const PreferResultList = observer(() => {
    const { resultStore } = useContext(SearchContext);
    const { preferResult } = resultStore;

    return (
        <Fragment>
            {preferResult.map((item, key) => (
                <PreferResultItem key={key} pharmacyItem={item} />
            ))}
        </Fragment>
    );
});

const MainResultList = observer(() => {
    const { resultStore } = useContext(SearchContext);
    const { mainResult, state } = resultStore;

    const onHoverHandler = (id, state) => {
        let currentItem = find(mainResult, ["id", id]);
        if (currentItem) {
            currentItem.switchHover(state, true);
        }
    };
    if (state === "done") {
        return (
            <Fragment>
                {mainResult.map((item) => (
                    <MainResultItem
                        key={item.id}
                        drugItem={item}
                        hover={item.hover}
                        hoverHandler={onHoverHandler}
                    />
                ))}
            </Fragment>
        );
    } else if (state === "pending") {
        return (
            <Fragment>
                <ListItemShimmer />
                <ListItemShimmer />
                <ListItemShimmer />
                <ListItemShimmer />
            </Fragment>
        );
    } else {
        return null;
    }
});

const NotFoundPrices = observer(() => {
    const { resultStore } = useContext(SearchContext);
    const { state, mainResult, preferResult } = resultStore;

    const contentError = (
        <div className="component__search-list--not-found">
            <TableError text="Something went wrong. Please try again." />
        </div>
    );

    const content = (
        <div className="component__search-list--not-found">
            <TableError
                type="not-found"
                text="We couldn't find any discounts. Change parameters and try again."
            />
        </div>
    );

    // show error just if evrything is empty
    if (state === "error" && preferResult.length === 0) {
        return contentError;
    } else if (
        state === "done" &&
        mainResult.length === 0 &&
        preferResult.length === 0
    ) {
        return content;
    } else {
        return null;
    }
});

function SearchList() {
    return (
        <div className="component__search-list">
            <ul className="component__search-list--results">
                <PreferResultList />
                <MainResultList />
            </ul>
            <NotFoundPrices />
        </div>
    );
}

export default SearchList;
