import PressLogos from "../../components/press-logos/PressLogos";
import PressArticles from "./press-articles/PressArticles";
import PressIllustrationImg from "./images/rx-man-press-3x.png";

import "./press-page.scss";

function PressPage() {
    return (
        <div className="page-press">
            <div className="container">
                <div className="row">
                    <div className="title">
                        <h1>Press Room</h1>
                        <p className="subtitle">
                            Stay up-to-update on our latest news and
                            announcements.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="press_logos-block">
                        <PressLogos />
                    </div>
                </div>
                <div className="row thin-custom-row">
                    <div className="press__about">
                        <div className="press__about-content">
                            <h2>
                                CharityRx coverage from sources across the web
                            </h2>
                            <p>
                                We are proud to have been featured in a number
                                of respected global media outlets, and we are
                                committed to continuing our work to create a
                                world where giving and helping people save on
                                prescription medications is simple, direct, and
                                transparent.
                            </p>
                            <p>
                                If you are a member of the media and would like
                                to cover CharityRx, please contact our press
                                team at{" "}
                                <a href={"mailto:press@charityrx.com"}>
                                    press@charityrx.com
                                </a>
                            </p>
                        </div>
                        <div className="press__about-image">
                            <img
                                src={PressIllustrationImg}
                                alt="CharityRx, LLC"
                                draggable={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="press__last-articles">
                        <div className="press__last-articles-title">
                            <h2>Latest Articles</h2>
                        </div>
                        <div className="last__articles">
                            <PressArticles />
                        </div>
                    </div>
                </div>
                <div className="row thin-custom-row">
                    <div className="page__info">
                        <h2>Get in touch</h2>
                        <div className="info__list">
                            <div className="info__list-item">
                                <p className="list__item-title">Email us</p>
                                <span className="list__item-value">
                                    <a href={"mailto:support@charityrx.com"}>
                                        support@charityrx.com
                                    </a>
                                </span>
                            </div>
                            <div className="info__list-item">
                                <p className="list__item-title">
                                    Customer Support
                                </p>
                                <span className="list__item-value">
                                    <a href={"tel:1-800-711-8154"}>
                                        1-800-711-8154
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row press-kit">
                    <div className="press-kit__title">
                        <h2>Our press kit</h2>
                        <p>
                            There you can find our logo and additional
                            information
                        </p>
                    </div>
                    <div className="press-kit__btn">
                        <a
                            href={require("./charityrx-press-kit.zip")}
                            download={"charityrx-press-kit.zip"}
                            rel="noreferrer"
                            target="_blank"
                        >
                            <button className="button purple">
                                Download .zip
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PressPage;
