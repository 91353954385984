import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const { REACT_APP_SENTRY_ENV, REACT_APP_SENTRY_DSN } = process.env;

const startSentryReport = () => {
    if (REACT_APP_SENTRY_DSN) {
        Sentry.init({
            dsn: REACT_APP_SENTRY_DSN,
            autoSessionTracking: true,
            environment: REACT_APP_SENTRY_ENV
                ? REACT_APP_SENTRY_ENV
                : "develop",
            integrations: [new BrowserTracing()],
            // We recommend adjusting this value in production, or using tracesSampler
            // for finer control
            tracesSampleRate: 0.1,
            ignoreErrors: [
                "Non-Error exception captured",
                "Illegal invocation",
            ],
        });
    }
};

const captureAxiosFailedRequest = (error) => {
    if (error.response && error.message) {
        Sentry.withScope((scope) => {
            const requestData = JSON.parse(error.response?.config?.data);

            scope.setTag("API Request:", "AXIOS");
            scope.setLevel("error");

            if (requestData !== undefined && requestData !== null) {
                scope.setFingerprint([
                    error.response.status,
                    requestData.action,
                ]);
                Sentry.setContext("API Request", {
                    status: error.response.status,
                    action: requestData.action,
                    data: JSON.stringify(requestData.data),
                });
            }

            Sentry.captureMessage(error.message, error);
        });
    } else {
        Sentry.captureException(error);
    }
};

export { startSentryReport, captureAxiosFailedRequest };
