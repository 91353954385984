import RxCard from "../../../components/rx-card/RxCard";
import RxCardActions from "../../../components/rx-card-actions/RxCardActions";
import ListBenefitsItems from "../../../components/list-benefits-items/ListBenefitsItems";

import "../get-coupon-page.scss";

function CouponEmptyContent() {
    return (
        <>
            <div className="row">
                <div className="page-coupon__content--title">
                    <h1 className="page-title">Get A Coupon</h1>
                    <p className="subtitle">
                        Download your CharityRx coupon instantly below and show
                        it to the pharmacist when you pick up your next
                        prescription. Your discount awaits!
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="page-coupon__content--rxcard">
                    <RxCard />
                    <RxCardActions />
                </div>
            </div>
            <ListBenefitsItems />
        </>
    );
}

export default CouponEmptyContent;
