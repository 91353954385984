import PropTypes from "prop-types";
import items from "./reviews.json";

import MaleAvatar from "./images/male.svg";
import FemaleAvatar from "./images/female.svg";

import "./section-reviews.scss";

const ReviewItem = ({ item }) => {
    let rating = [];
    for (let i = 0; i < item.rating; i++) {
        rating.push(<span className="star" key={i} />);
    }

    return (
        <div className="reviews__item">
            <div className="reviews__item--wrap">
                <div className="reviews__item--header">
                    <div className="review-header-block">
                        <img
                            alt=""
                            className="user-avatar"
                            src={
                                item.sex === "male" ? MaleAvatar : FemaleAvatar
                            }
                        />
                        <div className="user-info">
                            <span className="reviewer-name">{item.name}</span>
                            <span className="city">{item.city}</span>
                        </div>
                    </div>
                    <div className="header-block rate">{rating}</div>
                </div>
                <div className="reviews__item--content">
                    <p>{item.text}</p>
                </div>
            </div>
        </div>
    );
};

ReviewItem.propTypes = {
    item: PropTypes.object.isRequired,
};

function SectionReviews() {
    return (
        <div className="section__reviews">
            <h2>Reviews</h2>
            <p className="subtitle">
                CharityRx has helped millions of people save money on vital
                medications.
            </p>
            <div className="section__reviews--content">
                {items.map((item, i) => (
                    <ReviewItem key={i} item={item} />
                ))}
            </div>
        </div>
    );
}

export default SectionReviews;
