import "./tranding-coupons.scss";

import { useNavigate } from "react-router-dom";
import couponItems from "./items.json";
import { NavLink } from "react-router-dom";

function TrandingCoupons() {
    const navigation = useNavigate();
    const onClickHandler = (item) => {
        if (item && item !== null) {
            navigation(`/drug/${item.uri}`);
        }
    };

    return (
        <div className="component__ts">
            <div className="component__ts--wrap">
                <ul className="component__ts--list">
                    <li className="component__ts-list--title">
                        <p>Trending coupons:</p>
                    </li>
                    {couponItems.map((item, i) => (
                        <li
                            className="component__ts-list--item"
                            key={i}
                            onClick={() => onClickHandler(item)}
                        >
                            <NavLink to={`/drug/${item.uri}`}>
                                {item.name}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
export default TrandingCoupons;
