import PropTypes from "prop-types";
import { useState } from "react";

import "./dropdown.scss";

function PharmaciesTableDropdown({ currentValue, list, onChange }) {
    const [isActive, setActive] = useState(false);

    const toggleDropdown = () => {
        setActive(!isActive);
    };

    const onChangeLimitHandler = (value) => {
        onChange(value);
        setActive(false);
    };

    return (
        <div className={"dropdown " + (isActive && "dropdown--active")}>
            <div
                onClick={toggleDropdown}
                className="dropdown__toggle dropdown__title"
            >
                {currentValue}
            </div>
            <ul
                className={
                    "dropdown__list " + (isActive && "dropdown__list--active")
                }
            >
                {list.map(({ value }, index) => {
                    const isSelected = value === currentValue;
                    return (
                        <li
                            onClick={() => onChangeLimitHandler(value)}
                            key={index}
                            className={
                                "dropdown__list-item " +
                                (isSelected && "dropdown__list-item--active")
                            }
                        >
                            {value}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

PharmaciesTableDropdown.propTypes = {
    currentValue: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default PharmaciesTableDropdown;
