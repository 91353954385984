import { useContext } from "react";
import { observer } from "mobx-react-lite";
import { SearchContext } from "../../../../stores/contexts";

import "./mobile-result-sort.scss";

function MobileResultSort() {
    const { resultStore } = useContext(SearchContext);
    const { sort } = resultStore;

    const onClickHandler = (state) => {
        resultStore.switchSort(state);
    };

    return (
        <div className="component__mobile-result-filters">
            <div
                onClick={() => onClickHandler(true)}
                className={`component__mobile-filters-btn ${
                    sort === true ? "active" : ""
                }`}
            >
                Lowest prices
            </div>
            <div
                onClick={() => onClickHandler(false)}
                className={`component__mobile-filters-btn ${
                    sort === false ? "active" : ""
                }`}
            >
                Nearest pharmacies
            </div>
        </div>
    );
}
export default observer(MobileResultSort);
