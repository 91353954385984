import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import DrugDescription from "./DrugDescription";

import "./drug-card.scss";
import { getDrugDescription } from "../../../services/search.service";
import DrugCardShimmer from "./drug-card-shimmer/DrugCardShimmer";
import { useNavigate } from "react-router";
import SimilarDrugButton from "../../../components/similar-drug-button/SimilarDrugButton";

function DrugCard({ drug }) {
    const navigation = useNavigate();
    const [loading, setLoading] = useState(true);
    const [drugInfo, setDrugInfo] = useState({});

    useEffect(() => {
        let didCancel = false;
        async function getData(name) {
            setLoading(true);
            const result = await getDrugDescription(name);
            if (!didCancel) {
                if (result !== null) {
                    setLoading(false);
                    setDrugInfo(result);
                } else {
                    navigation("/drug");
                }
            }
        }
        getData(drug);

        return () => {
            didCancel = true;
        };
    }, [drug]);

    return (
        <div className="component__drugcard">
            <div className="container">
                <div className="row">
                    {!loading ? (
                        <div className="component__drugcard--content">
                            <h1>{drugInfo?.title}</h1>
                            <DrugDescription drugInfo={drugInfo} />
                            <div className="component__drugcard--similar">
                                {drugInfo.similar ? (
                                    <SimilarDrugButton
                                        similar={drugInfo.similar}
                                    />
                                ) : null}
                            </div>
                        </div>
                    ) : (
                        <div className="component__drugcard--shimmer">
                            <DrugCardShimmer />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

DrugCard.propTypes = {
    drug: PropTypes.string.isRequired,
};

export default DrugCard;
