import client from "./client";

const ENDPOINT = "/card";

export const DoDownloadCardRequest = async () => {
    try {
        const response = await client.get(`${ENDPOINT}/download`, {
            responseType: "blob",
        });
        return response;
    } catch (error) {
        return null;
    }
};

export const DoSendCardToEmailRequest = async (card, email) => {
    try {
        const response = await client.get(`${ENDPOINT}/email`, {
            params: { email, card },
        });
        return response.data;
    } catch (error) {
        return null;
    }
};

export const DoSendCardToPhoneRequest = async (card, to) => {
    try {
        const response = await client.get(`${ENDPOINT}/sms`, {
            params: { to, card },
        });
        return response.data;
    } catch (error) {
        return null;
    }
};
