import { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./faq-question-item.scss";

const FaqQuestionItem = ({ item }) => {
    const [isOpen, setOpen] = useState(false);

    const onClickHandler = () => {
        setOpen(!isOpen);
    };

    const itemClassNames = classnames({
        "faq-questions__item": true,
        open: isOpen,
    });

    return (
        <div className={itemClassNames}>
            <div className="faq-questions__item--content">
                <div className="title-block" onClick={onClickHandler}>
                    <h3>{item.title}</h3>
                    <span className="item-arrow" />
                </div>
                <div className="text-block">
                    <p dangerouslySetInnerHTML={{ __html: item.content }} />
                </div>
            </div>
        </div>
    );
};

FaqQuestionItem.propTypes = {
    item: PropTypes.object.isRequired,
};

export default FaqQuestionItem;
