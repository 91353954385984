import { filter, orderBy } from "lodash";
import { makeAutoObservable, action, toJS } from "mobx";
import { isAbortError } from "../../services/api/client";
import Drug from "./items/drug";
import Pharmacy from "./items/pharmacy";
export default class ResultStore {
    sort = true; // true - lowest // false - nearest
    state = "pending"; // "pending", "done" or "error"

    preferResultList = [];
    mainResultList = [];

    constructor(root) {
        makeAutoObservable(this);

        this.root = root;
    }

    get preferResult() {
        const result = toJS(this.preferResultList);
        if (result !== []) {
            return orderBy(result, ["pricing.price"], ["asc"]);
        } else {
            return [];
        }
    }

    get mainResult() {
        const result = this.mainResultList;
        if (result !== []) {
            const sortKey = this.sort ? "pricing.total" : "pharmacy.distance";
            return orderBy(result, [sortKey], ["asc"]);
        } else {
            return [];
        }
    }

    get itemsOnMap() {
        const result = this.mainResult;

        return filter(result, (o) => o.pharmacyCoords !== null);
    }

    get count() {
        return this.mainResult.length;
    }

    search(ndcs, unit) {
        this.reset();
        if (ndcs.length > 0 && unit > 0) {
            this.getPreferPharmacies({ ndcs, unit });
            this.getMainResults({ ndcs, unit });
        }
    }

    getPreferPharmacies(params) {
        this.root.service.getPreferPharmaciesResult(params).then(
            action("fetchSuccess", (result) => {
                let currentNdc = this.root.packagesStore.getSelectedItem();
                if (
                    result &&
                    Array.isArray(result) &&
                    result.length > 0 &&
                    currentNdc
                ) {
                    let items = result.map(
                        (item) => new Pharmacy({ ...item, ...currentNdc })
                    );
                    this.preferResultList = items;
                } else {
                    this.preferResultList = [];
                }
            }),
            action("fetchError", () => {
                // beacuse if we fetch a error we doesnt show results.
                this.preferResultList = [];
            })
        );
    }

    getMainResults(params) {
        this.root.service.getMainSearchResult(params).then(
            action("fetchSuccess", (result) => {
                if (result && Array.isArray(result) && result.length > 0) {
                    let drugs = result.map((i) => new Drug(i));
                    this.mainResultList = drugs;
                } else {
                    this.mainResultList = [];
                }
                this.state = "done";
            }),
            action("fetchError", (error) => {
                if (isAbortError(error)) {
                    this.state = "pending";
                } else {
                    this.state = "error";
                }
                this.mainResultList = [];
            })
        );
    }

    set(items) {
        if (items !== null && items.length > 0) {
            let drugs = items.map((i) => new Drug(i));
            this.mainResultList = drugs;
        }
    }

    switchSort(state) {
        if (typeof state === "boolean") {
            this.sort = state;
        } else {
            this.sort = true;
        }
    }

    reset() {
        this.state = "pending";

        this.preferResultList = [];
        this.mainResultList = [];
    }
}
