import { NavLink } from "react-router-dom";

import PropTypes from "prop-types";

import { filter } from "lodash";

import menuItems from "./items.json";
import "./navigation.scss";

const MenuLiItem = ({ url, name, external }) => {
    return (
        <li className="component__navigation--menu-item">
            {external ? (
                <a href={url} className="navigation__link link">
                    {name}
                </a>
            ) : (
                <NavLink to={url} className="navigation__link link">
                    {name}
                </NavLink>
            )}
        </li>
    );
};

MenuLiItem.propTypes = {
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    external: PropTypes.bool,
};

function DesktopNav() {
    const items = filter(menuItems, ["header", true]);

    return (
        <div className="component__navigation">
            <ul className="component__navigation--menu">
                {items.map((item, index) => (
                    <MenuLiItem
                        key={index}
                        url={item.url}
                        name={item.name}
                        external={item.external}
                    />
                ))}
            </ul>
        </div>
    );
}

export default DesktopNav;
