import PropTypes from "prop-types";
import ContentRxIcon from "../content-rx-icon/ContentRxIcon";

import CardBenefitsData from "./card-benefits-data.json";
import "./list-benefits-items.scss";

function ListBenefitsItems({ items }) {
    return (
        <div className="component__benefits">
            {items.map((item, key) => (
                <div key={key} className="benefit__item">
                    <ContentRxIcon
                        className="benefit__item--img"
                        imageAlt={item.title}
                        imageName={item.icon}
                    />
                    <div className="benefit__item--content">
                        <h3>{item.title}</h3>
                        <p dangerouslySetInnerHTML={{ __html: item.content }} />
                    </div>
                </div>
            ))}
        </div>
    );
}

ListBenefitsItems.propTypes = {
    items: PropTypes.array.isRequired,
};

ListBenefitsItems.defaultProps = {
    items: CardBenefitsData,
};

export default ListBenefitsItems;
