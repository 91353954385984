import PropTypes from "prop-types";

import cvsLogo from "./images/cvs.png";
import riteAidLogo from "./images/rite-aid.png";
import krogerLogo from "./images/kroger.png";
import targetLogo from "./images/target.png";
import walmartLogo from "./images/walmart.png";
import walgreensLogo from "./images/walgreens.png";
// import cvsLogo from "./images/cvs.svg";
// import riteAidLogo from "./images/rite-aid.svg";
// import krogerLogo from "./images/kroger.svg";
// import targetLogo from "./images/target.svg";
// import walmartLogo from "./images/walmart.svg";
// import walgreensLogo from "./images/walgreens.svg";

import cvsColorLogo from "./images/colors/cvs.png";
import riteAidColorLogo from "./images/colors/rite-aid.png";
import krogerColorLogo from "./images/colors/kroger.png";
import targetColorLogo from "./images/colors/target.png";
import walmartColorLogo from "./images/colors/walmart.png";
import walgreensColorLogo from "./images/colors/walgreens.png";

const blackAndWhitelist = [
    { imgSrc: cvsLogo, name: "CVS" },
    { imgSrc: walgreensLogo, name: "walgreens" },
    { imgSrc: targetLogo, name: "target" },
    { imgSrc: walmartLogo, name: "walmart" },
    { imgSrc: riteAidLogo, name: "riteAid" },
    { imgSrc: krogerLogo, name: "kroger" },
];

const colorList = [
    { imgSrc: cvsColorLogo, name: "CVS" },
    { imgSrc: walgreensColorLogo, name: "walgreens" },
    { imgSrc: targetColorLogo, name: "target" },
    { imgSrc: walmartColorLogo, name: "walmart" },
    { imgSrc: riteAidColorLogo, name: "riteAid" },
    { imgSrc: krogerColorLogo, name: "kroger" },
];

import "./pharmacy-partners-list.scss";

function PharmacyPartnersList({ color }) {
    const list = color ? colorList : blackAndWhitelist;
    return (
        <ul className="component__pharmacy_partner_list">
            {list.map((item, i) => (
                <li key={i} className="component__pharmacy_partner_list--item">
                    <div className={`item-${item.name}`}>
                        <img
                            className="img"
                            style={{ minHeight: "1px" }}
                            src={item.imgSrc}
                            alt={item.name}
                            draggable="false"
                        />
                    </div>
                </li>
            ))}
        </ul>
    );
}

PharmacyPartnersList.propTypes = {
    color: PropTypes.bool,
};

PharmacyPartnersList.defaultProps = {
    color: false,
};

export default PharmacyPartnersList;
