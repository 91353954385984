import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

import menuItems from "./items.json";

import "./navigation.scss";

const MenuLiItem = ({ url, name, external }) => {
    return (
        <li className="component__navigation-mobile--menu-item">
            {external ? (
                <a href={url} className="navigation__link link">
                    {name}
                </a>
            ) : (
                <NavLink to={url} className="navigation__link link">
                    {name}
                </NavLink>
            )}
        </li>
    );
};

MenuLiItem.propTypes = {
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    external: PropTypes.bool,
};

function MobileNav({ isOpen }) {
    const MobileNavRef = useRef(null);

    const preventDefault = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        // hack for stop scroll body.
        if (isOpen === true) {
            document.documentElement.classList.add("is-locked");

            MobileNavRef.current?.addEventListener(
                "pointermove",
                preventDefault
            );
        } else {
            document.documentElement.classList.remove("is-locked");

            MobileNavRef.current?.removeEventListener(
                "pointermove",
                preventDefault
            );
        }
    }, [isOpen]);

    return (
        <>
            {isOpen ? (
                <div
                    ref={MobileNavRef}
                    className="component__navigation-mobile"
                >
                    <ul className="component__navigation-mobile--list">
                        {menuItems.map((item, index) => (
                            <MenuLiItem
                                key={index}
                                url={item.url}
                                name={item.name}
                                external={item.external}
                            />
                        ))}
                    </ul>
                    <span className="component__navigation-mobile--disclaimer">
                        Copyright © 2021 CharityRx
                    </span>
                </div>
            ) : null}
        </>
    );
}

MobileNav.propTypes = {
    isOpen: PropTypes.bool.isRequired,
};

export default MobileNav;
