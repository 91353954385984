import GetACouponBtn from "../../../components/get-a-coupon-btn/GetACouponBtn";
import RxCard from "../../../components/rx-card/RxCard";
import "./section-card.scss";
function SectionCard() {
    return (
        <div className="section__card thin-custom-row">
            <div className="section__card--content">
                <h2>
                    CharityRx<small>&reg;</small> Pharmacy Card
                </h2>
                <p>
                    Download the CharityRx card and save up to 80% on your
                    prescriptions at 70,000+ pharmacies nationwide.
                </p>
                <GetACouponBtn title="Get a card" />
            </div>
            <div className="section__card--card">
                <RxCard withPattern={true} />
                <GetACouponBtn title="Get a card" />
            </div>
        </div>
    );
}

export default SectionCard;
