import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./similar-drug-button.scss";

function SimilarDrugButton({ similar }) {
    return (
        <div className="component__similar-drug">
            <div className="similar-drug__content">
                <span className="title">Similar:</span>
                <Link to={`/drug/${similar?.slug}`}>
                    <button className="button similar-btn">{`${similar?.name} (${similar?.type})`}</button>
                </Link>
            </div>
        </div>
    );
}

SimilarDrugButton.propTypes = {
    similar: PropTypes.object.isRequired,
};

export default SimilarDrugButton;
