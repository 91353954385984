import "./list-item-shimmer.scss";

function ListItemShimmer() {
    return (
        <div className="component__list-item-shimmer">
            <div className="shimmer-wrapper list-item__shimmer">
                <div className="list-item__shimmer--pharmacy">
                    <div className="shimmer shimmer-text bold" />
                    <div className="shimmer shimmer-text" />
                    <div className="shimmer shimmer-text" />
                </div>
                <div className="list-item__shimmer--package">
                    <div className="shimmer shimmer-text" />
                    <div className="shimmer shimmer-text" />
                </div>
                <div className="list-item__shimmer--content">
                    <div className="shimmer shimmer-price">$0.00</div>
                    <div className="shimmer shimmer-button" />
                </div>
            </div>
        </div>
    );
}

export default ListItemShimmer;
