import { useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { currencyFormat } from "../../../../utilities/helpers";

import GetACouponBtn from "../../../../components/get-a-coupon-btn/GetACouponBtn";

import "../search-result.scss";

const PackageColumn = ({ fullName, label }) => {
    return (
        <div className="search-result__item--package">
            <span className="title">{fullName}</span>
            <span className="pack-name">{label}</span>
        </div>
    );
};

PackageColumn.propTypes = {
    fullName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

function MainResultItem({ drugItem, hover, hoverHandler }) {
    const liRef = useRef();

    // const userHoveredOnCurrentElement = (e) => {
    //     return e.current.parentElement.querySelector(':hover') === e.current;
    // };

    // useEffect(() => {
    //     if (hover === true && liRef && liRef.current) {
    //         if (!userHoveredOnCurrentElement(liRef)) {
    //             liRef.current.scrollIntoView({
    //                 block: 'center',
    //                 behavior: 'smooth',
    //             });
    //         }
    //     }
    // }, [hover]);

    const liClassNames = classnames({
        "search-result__item": true,
        "is-selected": hover,
    });

    return (
        <li
            ref={liRef}
            className={liClassNames}
            onMouseEnter={() => hoverHandler(drugItem.id, true)}
            onMouseLeave={() => hoverHandler(drugItem.id, false)}
        >
            <div className="search-result__item--pharmacy">
                <span className="pharma-name">{drugItem.pharmacyName}</span>
                <span className="pharma-address">
                    {drugItem.pharmacyAddress}
                </span>
                <span className="pharma-hours">
                    {drugItem.pharmacyOpenHours}
                </span>
            </div>
            <div className="search-result__item--distance">
                <span className="title">Distance from you:</span>
                <span className="distance">{drugItem?.pharmacyDistance}</span>
            </div>
            <div className="search-result__item--content">
                <span className="price">{currencyFormat(drugItem.price)}</span>
                <GetACouponBtn ndc={drugItem.ndc11} />
            </div>
        </li>
    );
}

MainResultItem.propTypes = {
    drugItem: PropTypes.object.isRequired,
    hoverHandler: PropTypes.func.isRequired,
    hover: PropTypes.bool,
};

export default MainResultItem;
