import React from "react";
import { createRoot } from "react-dom/client";
import { startSentryReport } from "./utilities/sentry";

import App from "./containers/App";
import "./index.scss";

startSentryReport();

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);
