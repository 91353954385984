import axios from "axios";
import { captureAxiosFailedRequest } from "../../utilities/sentry";

const {
    REACT_APP_API_URI,
    REACT_APP_API_AUTH_USERNAME,
    REACT_APP_API_AUTH_PASSWORD,
} = process.env;

const apiUrl = REACT_APP_API_URI ? REACT_APP_API_URI : "http://localhost:8085";
const basicAuth = {
    username: REACT_APP_API_AUTH_USERNAME ? REACT_APP_API_AUTH_USERNAME : "",
    password: REACT_APP_API_AUTH_PASSWORD ? REACT_APP_API_AUTH_PASSWORD : "",
};

const apiAxios = axios.create({
    baseURL: apiUrl,
    auth: basicAuth,
});

apiAxios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        captureAxiosFailedRequest(error);
        return Promise.reject(error);
    }
);

export const isAbortError = (err) => {
    return (
        (typeof err === "object" && err.constructor.name === "Cancel") ||
        err?.message === "canceled"
    );
};

export default {
    get: apiAxios.get,
    post: apiAxios.post,
};
