import PropTypes from "prop-types";
import classnames from "classnames";
import { Fragment, useState, useEffect } from "react";

import PharmaciesTableDropdown from "../../../components/dropdown/PharmaciesTableDropdown";

import limits from "./limits.json";
import pharmacies from "./items.json";

import "./pharmacies-table.scss";

const filterPartnersData = (data, limit) => {
    const namesList = data.map((item) => item.pharmacyName);

    const firstLetter = limit[0];
    const lastLetter = limit[2];

    const startIndex = namesList.findIndex((name) => name[0] === firstLetter);

    const lastReversedIndex = namesList
        .reverse()
        .findIndex((name) => name[0] === lastLetter);
    const endIndex =
        namesList.length - (lastReversedIndex > -1 ? lastReversedIndex : 1);

    return data.slice(startIndex, endIndex);
};

const TableItemsByWord = ({ className, value }) => {
    const liClassNames = classnames({
        "pharmacies-table__item": true,
        [className]: className,
    });
    return <li className={liClassNames}>{value}</li>;
};

TableItemsByWord.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string.isRequired,
};

function PharmaciesTable() {
    const [filteredData, setFilteredData] = useState([]);
    const [selectedLimit, setSelectedLimit] = useState("A-D");

    useEffect(() => {
        setFilteredData(filterPartnersData(pharmacies, selectedLimit));
    }, [selectedLimit]);

    const onClickLimitHandler = (limit) => {
        setSelectedLimit(limit);
    };

    const rowsCount = Math.ceil((filteredData.length + 4) / 3);

    return (
        <div className="component__pharmacies-table">
            <PharmaciesTableDropdown
                currentValue={selectedLimit}
                list={limits}
                onChange={onClickLimitHandler}
            />

            <div className="pharmacies-table__wrapper">
                <ul
                    className="pharmacies-table__list"
                    style={{ gridTemplateRows: `repeat(${rowsCount}, 1fr)` }}
                >
                    {filteredData.map((item, index, array) => {
                        const previousLetter =
                            array[index - 1] &&
                            array[index - 1].pharmacyName[0];
                        const currentLetter = item.pharmacyName[0];

                        if (previousLetter !== currentLetter) {
                            return (
                                <Fragment key={item.id}>
                                    <TableItemsByWord
                                        className="pharmacies-table__item--first-letter"
                                        key={currentLetter}
                                        value={currentLetter}
                                    />

                                    <TableItemsByWord
                                        key={item.id}
                                        value={item.pharmacyName}
                                    />
                                </Fragment>
                            );
                        }

                        return (
                            <TableItemsByWord
                                key={item.id}
                                value={item.pharmacyName}
                            />
                        );
                    })}
                </ul>
            </div>
            <ul className="pharmacies-table__letter-pagination">
                {limits.map(({ value, padding }, index) => {
                    const isSelected = selectedLimit === value;

                    return (
                        <li
                            className={`letter-pagination__item ${
                                isSelected &&
                                "letter-pagination__item--selected"
                            }`}
                            key={`${value}${index}`}
                            onClick={() => onClickLimitHandler(value)}
                            style={{ padding }}
                        >
                            {value}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default PharmaciesTable;
