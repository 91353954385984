import { DoGpsLookup } from "../services/api/api.helpers";
import { getUserLocation } from "../stores/location.store";

export const InnerWidthForDesktop = 860;

export const currencyFormat = (currencyValue) => {
    if (currencyValue === undefined) return "";

    let value =
        typeof currencyValue == "number"
            ? currencyValue
            : parseFloat(currencyValue);

    return "$" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const validateEmail = (email) => {
    const regex =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g; /* eslint-disable-line */

    return email.match(regex);
};

export const fetchGeoLocationFromBrowser = async (force = false) => {
    const location = getUserLocation();
    return new Promise((resolve, reject) => {
        if (force === true || location.have() === false) {
            navigator.geolocation.getCurrentPosition(
                async ({ coords }) => {
                    const { latitude, longitude } = coords;
                    const { zipcode } = await DoGpsLookup({
                        latitude,
                        longitude,
                    });

                    if (zipcode === undefined) {
                        reject(false);
                    }

                    location.set(zipcode, coords);

                    resolve({ zipcode });
                },
                () => {
                    // permisions denied
                    reject(false);
                }
            );
        } else {
            reject(false);
        }
    }).catch(() => false);
};
