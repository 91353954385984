import { find } from "lodash";
import { useLocation } from "react-router-dom";

import RxCard from "../../components/rx-card/RxCard";
import RxCardActions from "../../components/rx-card-actions/RxCardActions";
import ListBenefitsItems from "../../components/list-benefits-items/ListBenefitsItems";
import PageSectionSubscription from "../../components/page-section-subscription/PageSectionSubscription";

import "./get-card-page.scss";
import { Helmet } from "react-helmet-async";

const PHARMACIES = [
    {
        path: "rite-aid",
        title: "Rite Aid",
    },
    {
        path: "kroger",
        title: "Kroger",
    },
    {
        path: "publix",
        title: "Publix",
    },
    {
        path: "walmart",
        title: "Walmart",
    },
    {
        path: "cvs",
        title: "CVS",
    },
    {
        path: "walgreens",
        title: "Walgreens",
    },
    {
        path: "target",
        title: "Target",
    },
];

const usePathName = () => {
    const { pathname } = useLocation();

    return pathname.replace("/", "");
};

function PharmacyTargetPage() {
    const pharmName = usePathName();
    const title = find(PHARMACIES, (o) => o.path === pharmName).title;

    return (
        <>
            <Helmet>
                <title>{`CharityRx™ - Best Prescription Discount Card | PharmacyRx ${title} Card`}</title>
                <meta
                    name="description"
                    content={
                        "Discover different discount RX programs that we offer and see how you can save up to 80 percent on your prescription. Explore more about pharmacy RX cards."
                    }
                />
            </Helmet>
            <div className="page-get-card">
                <div className="container">
                    <div className="row">
                        <div className="page-get-card__content">
                            <div className="page-get-card__content--title">
                                <h1 className="page-title">
                                    {title !== undefined
                                        ? `Get Your ${title} Card Today`
                                        : "Get Your Card Today"}
                                </h1>
                                <p className="subtitle">
                                    Download your CharityRx discount card and
                                    simply present it to the pharmacist on your
                                    next prescription purchase for instant
                                    savings!
                                </p>
                            </div>
                            <div className="page-get-card__content--rxcard">
                                <RxCard cardName={pharmName} />
                                <RxCardActions cardName={pharmName} />
                            </div>
                            <div className="page-get-card__content--info">
                                <ListBenefitsItems />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <PageSectionSubscription />
                    </div>
                </div>
            </div>
        </>
    );
}

export default PharmacyTargetPage;
