import { findIndex, get, has, isEmpty } from "lodash";
import { makeAutoObservable, action, toJS, reaction } from "mobx";

const formSelectData = (obj) =>
    Object.keys(obj).map((item) => ({ label: item, value: item }));

export default class FiltersStore {
    currentRoute = 0; // key of current form object 0, 1, 2"
    currentForm = 0; //
    currentStrength = 0; //

    resultList = [];
    defaultList = [];
    state = "pending"; // "pending", "done" or "error"

    constructor(root) {
        makeAutoObservable(this);
        this.root = root;

        this.updateNDC = reaction(
            () => this.strength,
            () => {
                this.setPackage();
            }
        );
    }

    get filters() {
        return toJS(this.resultList);
    }

    get default() {
        return toJS(this.defaultList);
    }

    get routes() {
        if (this.filters !== []) {
            return formSelectData(this.filters);
        } else {
            return [];
        }
    }

    get route() {
        if (this.routes !== []) {
            if (this.routes[this.currentRoute] !== undefined) {
                return this.routes[this.currentRoute];
            }
        }

        return null;
    }

    selectRoute(newItemValue) {
        let newItemKey = findIndex(this.routes, ["value", newItemValue]);
        if (newItemKey !== this.currentRoute) {
            this.currentRoute = newItemKey;
            this.currentForm = 0;
            this.currentStrength = 0;
        }
    }

    get forms() {
        let key = this?.route?.value;
        if (this.filters && key && this.filters[key]) {
            return formSelectData(this.filters[key]);
        } else {
            return [];
        }
    }

    get form() {
        if (this.forms !== []) {
            if (this.forms[this.currentForm] !== undefined) {
                return this.forms[this.currentForm];
            }
        }

        return null;
    }

    selectForm(newItemValue) {
        let newItemKey = findIndex(this.forms, ["value", newItemValue]);
        if (newItemKey !== this.currentForm) {
            this.currentForm = newItemKey;
            this.currentStrength = 0;
        }
    }

    get strengths() {
        let keyRoute = this?.route?.value;
        let keyForm = this?.form?.value;
        if (this.filters && keyRoute && keyForm) {
            return formSelectData(this.filters[keyRoute][keyForm]);
        } else {
            return [];
        }
    }

    get strength() {
        if (this.strengths !== []) {
            if (this.strengths[this.currentStrength] !== undefined) {
                return this.strengths[this.currentStrength];
            }
        }

        return null;
    }

    selectStrength(newItemValue) {
        let newItemKey = findIndex(this.strengths, ["value", newItemValue]);
        if (newItemKey !== -1 && newItemKey !== this.currentStrength) {
            this.currentStrength = newItemKey;
        }
    }

    init(drugName) {
        this.reset();
        this.root.service.getDrugPrescription(drugName).then(
            action("fetchSuccess", (result) => {
                if (result === null) this.state = "error";

                if (has(result, "filters")) {
                    this.resultList = result.filters;
                }
                if (has(result, "default")) {
                    this.defaultList = result.default;
                }
                this.setCurrentFilter();
                this.state = "done";
            }),
            action("fetchError", () => {
                this.state = "error";
            })
        );
    }

    setCurrentFilter() {
        if (!isEmpty(this.filters) && !isEmpty(this.default)) {
            let routeName = Object.keys(this.default)[0];
            if (routeName) {
                this.selectRoute(routeName);
            }

            let formName = Object.keys(get(this.default, [routeName]))[0];
            if (formName) {
                this.selectForm(formName);
            }

            let strengthName = Object.keys(
                get(this.default, [routeName, formName])
            )[0];
            if (strengthName) {
                this.selectStrength(strengthName);
            }
        } else {
            // this.setPackage();
        }
    }

    setPackage() {
        let ndcs = get(this.filters, [
            this.route?.value,
            this.form?.value,
            this.strength?.value,
        ]);
        if (ndcs) {
            this.root.packagesStore.update(ndcs);
        }
    }

    reset() {
        this.currentRoute = 0;
        this.currentForm = 0;
        this.currentStrength = 0;

        this.resultList = [];
        this.defaultList = [];

        // reset all current stores.
        this.root.packagesStore.reset();
        this.root.resultStore.reset();
    }
}
