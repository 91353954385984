import { useEffect } from "react";
import PageSectionSubscription from "../../components/page-section-subscription/PageSectionSubscription";
import { fetchGeoLocationFromBrowser } from "../../utilities/helpers";
import SectionApp from "./section-app/SectionApp";
import SectionCard from "./section-card/SectionCard";
import SectionHero from "./section-hero/SectionHero";
import SectionHowItWorks from "./section-how-it-works/SectionHowItWorks";
import SectionPartners from "./section-partners/SectionPartners";
import SectionQuestions from "./section-questions/SectionQuestions";
import SectionReviews from "./section-reviews/SectionReviews";
import SectionSampleSaving from "./section-sample-saving/SectionSampleSaving";
import SectionPressPartners from "./section-press-partners/SectionPressPartners";

export default function HomePage() {
    useEffect(() => {
        fetchGeoLocationFromBrowser();
    }, []);

    return (
        <div className="page-home-page">
            <div className="container-fluid">
                <div className="row">
                    <SectionHero />
                </div>
                <div className="row">
                    <SectionPressPartners />
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <SectionCard />
                </div>
                <div className="row">
                    <SectionHowItWorks />
                </div>
                <div className="row">
                    <SectionSampleSaving />
                </div>
                <div className="row">
                    <SectionApp />
                </div>
                <div className="row">
                    <SectionQuestions />
                </div>
                <div className="row">
                    <SectionReviews />
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <SectionPartners withTitle={true} withLink={true} />
                </div>
                <div className="row">
                    <PageSectionSubscription />
                </div>
            </div>
        </div>
    );
}
