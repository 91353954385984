import PropTypes from "prop-types";

import { useEffect, useState } from "react";
import { QRCodeModal, TextModal, EmailModal } from "./modals/CardRxModals";

import "./rx-card-actions.scss";

function RxOrgCardActions({ type }) {
    const [currentPdfCard, setCurrentPdfCard] = useState(null);

    const [showQrCode, switchQrCode] = useState(false);
    const [showTextModal, switchTextModal] = useState(false);
    const [showEmailModal, switchEmailModal] = useState(false);

    const onPrintHandler = (e) => {
        e.preventDefault();
        window.print();
    };

    useEffect(() => {
        switch (type) {
            case "100K12":
                setCurrentPdfCard({
                    href: require("../rx-org-card/images/CharityRx_CaneBay_card.pdf"),
                    name: "Charity_Rx_card.pdf",
                });
                break;
            case "101K12":
                setCurrentPdfCard({
                    href: require("../rx-org-card/images/IronCountySD-School-Rx-Card.pdf"),
                    name: "Charity_Rx_card.pdf",
                });
                break;
            case "102K12":
                setCurrentPdfCard({
                    href: require("../rx-org-card/images/Heights-School-Rx-Card.pdf"),
                    name: "Charity_Rx_card.pdf",
                });
                break;

            default:
                setCurrentPdfCard(null);
                break;
        }
    }, [type]);

    return (
        <>
            <div className="rxcard-org__actions">
                <div className="rxcard-org__actions-item download">
                    {currentPdfCard !== null && (
                        <a
                            href={currentPdfCard.href}
                            download={currentPdfCard.name}
                            style={{ width: "100%" }}
                            rel="noreferrer"
                            target="_blank"
                        >
                            <button className="button purple download-btn">
                                Download
                            </button>
                        </a>
                    )}
                </div>
                <div className="rxcard-org__actions-item">
                    <button
                        className="button blue via-sms"
                        onClick={() => switchTextModal(!showTextModal)}
                    >
                        Send via text
                    </button>
                </div>
                <div className="rxcard-org__actions-item">
                    <button
                        className="button blue via-mail"
                        onClick={() => switchEmailModal(!showEmailModal)}
                    >
                        Send via email
                    </button>
                </div>
                <div className="rxcard-org__actions-item">
                    <button
                        className="button blue via-qr"
                        onClick={() => switchQrCode(!showQrCode)}
                    >
                        Save QR code
                    </button>
                </div>
                <div className="rxcard-org__actions-item">
                    <button
                        className="button blue print"
                        onClick={onPrintHandler}
                    >
                        Print coupon
                    </button>
                </div>
            </div>
            {showQrCode ? (
                <QRCodeModal closeHandler={() => switchQrCode(false)} />
            ) : null}
            {showTextModal ? (
                <TextModal
                    cardType={type}
                    closeHandler={() => switchTextModal(false)}
                />
            ) : null}
            {showEmailModal ? (
                <EmailModal
                    cardType={type}
                    closeHandler={() => switchEmailModal(false)}
                />
            ) : null}
        </>
    );
}

RxOrgCardActions.propTypes = {
    type: PropTypes.string,
};

export default RxOrgCardActions;
