import PropTypes from "prop-types";
import ContentRxIcon from "../content-rx-icon/ContentRxIcon";
import highlights from "./highlights.json";

import "./list-highlights-items.scss";

function ListHighlightsItems({ items }) {
    return (
        <div className="component__highlights">
            {items.map((item, key) => (
                <div key={key} className="highlights__item">
                    <ContentRxIcon
                        className="highlights__item--img"
                        imageAlt={item.title}
                        imageName={item.img}
                    />
                    <div className="highlights__item--content">
                        <h3>{item.title}</h3>
                        <p>{item.content}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}

ListHighlightsItems.propTypes = {
    items: PropTypes.array.isRequired,
};

ListHighlightsItems.defaultProps = {
    items: highlights,
};

export default ListHighlightsItems;
