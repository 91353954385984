import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import CardImage from "./images/rx-org-card-1x.png";
import CardImage2x from "./images/rx-org-card-2x.png";
import CardImage3x from "./images/rx-org-card-3x.png";

import Card101K_en from "./images/IronCountySD-School-Rx-Card-1.png";
import Card102K_en from "./images/Heights-School-Rx-Card-1.png";

import "./rx-card.scss";

function RxOrgCard({ type }) {
    const [card, setCard] = useState(null);

    useEffect(() => {
        switch (type) {
            case "100K12":
                setCard(
                    <img
                        className="component__rxcard--card"
                        src={CardImage}
                        srcSet={`${CardImage2x} 2x, ${CardImage3x} 3x`}
                        alt="CharityRx Cane Bay Card"
                        draggable={false}
                    />
                );
                break;
            case "101K12":
                setCard(
                    <div className="component__rxcard-cards-wrap">
                        <img
                            className="component__rxcard--card component__rxcard--card-radius"
                            src={Card101K_en}
                            // srcSet={`${CardImage2x} 2x, ${CardImage3x} 3x`}
                            alt="CharityRx Cane Bay Card"
                            draggable={false}
                        />
                        {/* <img
                            className="component__rxcard--card component__rxcard--card-radius"
                            src={Card101K_es}
                            // srcSet={`${CardImage2x} 2x, ${CardImage3x} 3x`}
                            alt="CharityRx Cane Bay Card"
                            draggable={false}
                        /> */}
                    </div>
                );
                break;
            case "102K12":
                setCard(
                    <div className="component__rxcard-cards-wrap">
                        <img
                            className="component__rxcard--card component__rxcard--card-radius"
                            src={Card102K_en}
                            // srcSet={`${CardImage2x} 2x, ${CardImage3x} 3x`}
                            alt="CharityRx Cane Bay Card"
                            draggable={false}
                        />
                        {/* <img
                            className="component__rxcard--card component__rxcard--card-radius"
                            src={Card102K_es}
                            // srcSet={`${CardImage2x} 2x, ${CardImage3x} 3x`}
                            alt="CharityRx Cane Bay Card"
                            draggable={false}
                        /> */}
                    </div>
                );
                break;

            default:
                setCard(null);
                break;
        }
    }, [type]);

    return <div className="component__rxcard">{card}</div>;
}

RxOrgCard.propTypes = {
    type: PropTypes.string,
};

export default RxOrgCard;
