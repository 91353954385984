import "./search-loader.scss";

function SearchLoader() {
    return (
        <div className="component__searchloader">
            <div className="component__searchloader--balls">
                <div />
                <div />
                <div />
            </div>
        </div>
    );
}

export default SearchLoader;
