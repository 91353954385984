import * as localStorage from "store";

const STORAGE_KEY = "user_location";

class locationObject {
    type = "zip"; // zip / coords / address
    radius = 5;

    _zip = null;
    _coords = null;

    _default = "33141";

    constructor() {
        this.init();
    }

    get zip() {
        if (this._zip !== null) {
            return this._zip;
        } else {
            return this._default;
        }
    }

    get addrObject() {
        return {
            type: "zip",
            radius: this.radius,
            addressObject: {
                zip: this.zip,
            },
        };
    }

    init() {
        if (this.have()) {
            const state = localStorage.get(STORAGE_KEY);

            this.type = state.type;
            this.radius = state.radius;
            this._zip = state.zip;

            return this;
        }
    }

    set(zipcode = "", coords = "") {
        this.clear();

        if (zipcode !== "") this.setZip(zipcode);

        if (coords !== "") this.setCoords(coords);

        this.save();
    }

    setZip(zipcode) {
        this._zip = zipcode;
    }

    setCoords(coords) {
        this._coords = coords;
    }

    setRadius(val, saving = true) {
        this.radius = parseInt(val);

        if (saving) this.save();
    }

    have() {
        const state = localStorage.get(STORAGE_KEY);
        if (
            state !== undefined &&
            typeof state === "object" &&
            state !== null
        ) {
            return true;
        } else {
            return false;
        }
    }

    save() {
        localStorage.set(STORAGE_KEY, {
            type: this.type,
            radius: this.radius,
            zip: this._zip,
        });

        return true;
    }

    clear() {
        localStorage.remove(STORAGE_KEY);

        this.type = "zip"; // zip / coords / address
        this.radius = 5;

        this._zip = null;
    }
}

export const getUserLocation = () => {
    return new locationObject();
};
