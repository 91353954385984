import PropTypes from "prop-types";
import classnames from "classnames";
import { useEffect, useRef } from "react";

import { SuccessfulMan } from "../man-states/ManStates";

import "./modal-window.scss";

function ModalSmallWindow({
    children,
    className,
    successMsg,
    showSuccessMsg,
    closeHandler,
}) {
    const modalRef = useRef(null);

    function handleKeyUp(e) {
        const keys = {
            27: () => {
                e.preventDefault();
                closeHandler();
            },
        };

        if (keys[e.keyCode]) {
            keys[e.keyCode]();
        }
    }

    function handleClickOutside(e) {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            closeHandler();
        }
    }

    useEffect(() => {
        // hack for stop scroll body.
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "unset";
        };
    }, []);

    useEffect(() => {
        // Bind the event listener
        window.addEventListener("keyup", handleKeyUp);
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            window.addEventListener("keyup", handleKeyUp);
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    const modalClasses = classnames({
        "component__modal-small-window": true,
        [className]: className,
    });

    const modalBodyClasses = classnames({
        "modal-small__body": true,
        success: showSuccessMsg,
    });

    return (
        <div className={modalClasses}>
            <div className="modal-small__wrap">
                <div className={modalBodyClasses} ref={modalRef}>
                    <div className="modal-small__title">
                        <div className="block">
                            <span
                                className="close-icon"
                                onClick={() => closeHandler()}
                            />
                        </div>
                    </div>
                    <div className="modal-small__content">{children}</div>
                    <div className="modal-small__successs">
                        <div className="modal-small__successs--wrap">
                            <SuccessfulMan />
                            <p className="msg">{successMsg}</p>
                            <button
                                className="button purple"
                                onClick={() => closeHandler()}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

ModalSmallWindow.propTypes = {
    className: PropTypes.string,
    successMsg: PropTypes.string,
    showSuccessMsg: PropTypes.bool.isRequired,
    children: PropTypes.any,
    closeHandler: PropTypes.func.isRequired,
};

ModalSmallWindow.defaultProps = {
    successMsg: "Everything worked out!",
    showSuccessMsg: false,
};

export default ModalSmallWindow;
