import { useContext, useRef, useEffect, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { SearchContext } from "../../stores/contexts";
import { getUserLocation } from "../../stores/location.store";

import LocationInput from "../location/LocationInput";
import LocationRadiusSlider from "../location/LocationRadiusSlider";

import "./location-dropdown.scss";

const SaveLocationBtn = ({ onClickHandler }) => {
    return (
        <button
            className="button purple save-address-btn"
            onClick={() => onClickHandler()}
        >
            Search
        </button>
    );
};

SaveLocationBtn.propTypes = {
    onClickHandler: PropTypes.func.isRequired,
};

function LocationDropdown() {
    const location = getUserLocation();
    const dropdownRef = useRef(null);
    const { packagesStore } = useContext(SearchContext);

    const [open, setOpen] = useState(false);
    const [initedLocation, setInitedLocation] = useState(() => ({
        zip: location.zip,
        radius: location.radius,
    }));
    const [zipValue, setZipValue] = useState(() => location.zip);
    const [radiusValue, setRadiusValue] = useState(() => location.radius);

    useEffect(() => {
        if (open) {
            setInitedLocation({
                zip: location.zip,
                radius: location.radius,
            });
        }
    }, [open]);

    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            closeHandler();
        }
    };

    // Check each value for should refresh location.
    const closeHandler = () => {
        let mustSearch = false;
        if (zipValue !== initedLocation.zip) {
            location.set(zipValue);
            mustSearch = true;
        }

        if (radiusValue !== initedLocation.radius) {
            location.setRadius(radiusValue);
            mustSearch = true;
        }

        if (mustSearch) {
            packagesStore.initSearch();
        }

        setOpen(false);
    };

    const menuClassNames = classnames({
        "component__location-dropdown--menu": true,
        open: open,
    });

    const openIconClassNames = classnames({
        "close-open__icon": true,
        "is-active": open,
    });

    return (
        <div ref={dropdownRef} className="component__location-dropdown">
            <div
                className="component__location-dropdown--current"
                onClick={() => setOpen(!open)}
            >
                <span className="value">{zipValue}</span>
                <span className={openIconClassNames} />
            </div>
            <div className={menuClassNames}>
                <LocationInput
                    value={zipValue}
                    containerStyle="location--dropdown__address"
                    inputStyle="location--dropdown__address--input"
                    onChangeHandler={(val) => setZipValue(val)}
                />
                <LocationRadiusSlider
                    value={radiusValue}
                    onChangeHandler={(val) => setRadiusValue(val)}
                />
                <SaveLocationBtn onClickHandler={closeHandler} />
            </div>
        </div>
    );
}

LocationDropdown.propTypes = {};

export default LocationDropdown;
