import ReactGA from "react-ga4";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { find } from "lodash";

import seoData from "./seo.json";

const { REACT_APP_GA } = process.env;

if (REACT_APP_GA && REACT_APP_GA.length > 0) {
    ReactGA.initialize(REACT_APP_GA);
}

function AppNavigator() {
    const [metaData, setMetaData] = useState(seoData[0]);
    const { pathname } = useLocation();

    // scrool to top always when we click to link
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // Change metadata for each page
    useEffect(() => {
        let data = find(seoData, ["route", pathname]);
        if (data) {
            setMetaData(data);
        } else {
            setMetaData(seoData[0]);
        }
    }, [pathname]);

    //
    useEffect(() => {
        if (ReactGA.isInitialized) {
            ReactGA.send("pageview");
        }
    }, [pathname]);

    return (
        <Helmet>
            <title>{metaData.title}</title>
            <meta name="description" content={metaData.description} />
        </Helmet>
    );
}

export default AppNavigator;
