import SearchBar from "../../components/search-bar/SearchBar";
import NotFoundErrorImg from "../../components/errors/NotFoundErrorImg";

import "./search-page.scss";

function SearchPageNotFound() {
    return (
        <div className="page-search-not-found">
            <div className="container">
                <div className="row">
                    <div className="search-not-found__content">
                        <div className="search-not-found__content--img">
                            <NotFoundErrorImg />
                        </div>
                        <div className="search-not-found__content--text">
                            <h1>We can not find your request</h1>
                            <p className="subtitle">
                                Please try another request
                            </p>
                        </div>
                    </div>
                    <div className="search-not-found__search-bar">
                        <SearchBar />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchPageNotFound;
