import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

import cnbcLogo from "./images/cnbc.png";
import dailyBeastLogo from "./images/the-daily-beast.png";
import forbesLogo from "./images/forbes.png";
import theGuardianLogo from "./images/the-guardian.png";
import theHillLogo from "./images/the-hill.png";
import yahooNewsLogo from "./images/yahoo-news.png";
import usWeeklyLogo from "./images/us-weekly.png";

import "./press-logos.scss";

const list = [
    { img: cnbcLogo, alt: "CNBC", link: "https://www.cnbc.com" },
    {
        img: dailyBeastLogo,
        alt: "The Daily Beast",
        link: "https://www.thedailybeast.com/",
    },
    { img: forbesLogo, alt: "Forbes", link: "https://www.forbes.com/" },
    {
        img: theGuardianLogo,
        alt: "The Guardian",
        link: "https://www.theguardian.com/",
    },
    { img: theHillLogo, alt: "The Hill", link: "https://thehill.com/" },
    { img: yahooNewsLogo, alt: "Yahoo News", link: "https://news.yahoo.com/" },
    {
        img: usWeeklyLogo,
        alt: "Us Weekly",
        height: 45,
        link: "https://www.usmagazine.com/",
    },
];

function PressLogos() {
    const [emblaRef] = useEmblaCarousel(
        {
            loop: true,
            align: "center",
        },
        [Autoplay({ delay: 5000 })]
    );

    return (
        <div className="press-logos__embla">
            <div className="embla__viewport" ref={emblaRef}>
                <div className="embla__container">
                    {list.map((item, i) => (
                        <div key={i} className="press-logos__item">
                            <a
                                href={item.link}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={item.img}
                                    alt={item.alt}
                                    draggable={false}
                                    style={{
                                        height: item?.height ? item.height : 60,
                                    }}
                                />
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default PressLogos;
