import client from "./client";

const ENDPOINT = "drugs";

let pharmaciesSearchController, searchPreferController, searchController;

export const DoGetPharmaciesByLocation = async (location) => {
    try {
        if (pharmaciesSearchController) pharmaciesSearchController.abort();
        pharmaciesSearchController = new AbortController();

        const response = await client.post(
            "/",
            {
                action: `${ENDPOINT}/search/pharmacies`,
                method: "post",
                data: { location },
            },
            {
                signal: pharmaciesSearchController.signal,
            }
        );

        return response.data;
    } catch (error) {
        // console.log("error: ", error);
        return null;
    }
};

export const DoGetPriceByPharmacy = async (data) => {
    try {
        const response = await client.post("/", {
            action: `${ENDPOINT}/search/pharmacy/price`,
            method: "post",
            data,
        });

        return response.data;
    } catch (error) {
        // console.log("error: ", error);
        return null;
    }
};

export const DoGetPreferPharmaciesSearch = async (data) => {
    try {
        if (searchPreferController) searchPreferController.abort();
        searchPreferController = new AbortController();

        const response = await client.post(
            "/",
            {
                action: `${ENDPOINT}/search/prefer`,
                method: "post",
                data,
            },
            {
                timeout: 36000,
                signal: searchPreferController.signal,
            }
        );
        return response.data;
    } catch (error) {
        return null;
    }
};

export const DoGetMainSearch = async (data) => {
    if (searchController) searchController.abort();
    searchController = new AbortController();

    const response = await client.post(
        "/",
        {
            action: `${ENDPOINT}/search`,
            method: "post",
            data,
        },
        {
            timeout: 36000,
            signal: searchController.signal,
        }
    );
    return response.data;
};
