import classnames from "classnames";
import PropTypes from "prop-types";

import Spinner from "../spinner/Spinner";

import "./loading-btn.scss";

function LoadingBtn({ classNames, btnText, isLoading, click }) {
    const btnClassNames = classnames({
        "button purple loading-btn": true,
        [classNames]: true,
        loading: isLoading,
    });
    return (
        <button type="submit" className={btnClassNames} onClick={click}>
            <span>{btnText}</span>
            {isLoading ? <Spinner /> : null}
        </button>
    );
}

LoadingBtn.propTypes = {
    classNames: PropTypes.string,
    btnText: PropTypes.string,
    isLoading: PropTypes.bool,
    click: PropTypes.func,
};

LoadingBtn.defaultProps = {
    btnText: "submit",
    isLoading: false,
};

export default LoadingBtn;
