import { NavLink } from "react-router-dom";
import NotFoundImage from "./images/not-found-img.svg";

import "./not-found-page.scss";

export default function NotFoundPage() {
    return (
        <div className="page-notfound">
            <div className="container">
                <div className="row">
                    <div className="page-notfound__wrap">
                        <div className="page-notfound__wrap--content">
                            <img src={NotFoundImage} />
                            <h1>Oops, This Page Could Not Be Found.</h1>
                            <p>
                                The page you are looking for might have been
                                removed had its name changed or is temporarily
                                unavailable.
                            </p>
                            <NavLink to="/">
                                <button className="button purple">
                                    Back to Home
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
