import PropTypes from "prop-types";
import classnames from "classnames";

import "./map-marker.scss";

function MapMarker({ hover }) {
    const markerClassNames = classnames({
        "component__map-marker": true,
        "is-selected": hover,
    });

    return <div className={markerClassNames} />;
}

MapMarker.propTypes = {
    hover: PropTypes.bool,
};

export default MapMarker;
