import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

// import logoBlack from './images/black.svg';
// import logoBlue from './images/blue.svg';
import logoWhite from "./images/white.svg";
// import logoMain from './images/main.svg';
import logoColor from "./images/color.svg";

import "./logo.scss";

function Logo({ type }) {
    const [logoUrl, setLogoUrl] = useState();
    useEffect(() => {
        switch (type) {
            case "color":
                setLogoUrl(logoColor);
                break;
            case "white":
                setLogoUrl(logoWhite);
                break;

            default:
                break;
        }
    }, [type]);

    return (
        <Link to="/" className="component-logo">
            <img alt="CharityRx" src={logoUrl} draggable="false" />
        </Link>
    );
}

Logo.propTypes = {
    type: PropTypes.oneOf(["color", "white"]),
};

export default Logo;
