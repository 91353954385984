import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { useContext, useEffect } from "react";

import Select from "react-select";
import { SearchContext } from "../../../stores/contexts";
import LoadingBtn from "../../../components/loading-btn/LoadingBtn";
import PackagesDropdown from "../../../components/dropdown/packages/PackagesDropdown";

import "./drug-prescription.scss";

const RouteSelect = observer(() => {
    const { filtersStore } = useContext(SearchContext);
    const { routes, route } = filtersStore;

    const onChangeHandler = ({ value }) => {
        filtersStore.selectRoute(value);
    };

    return (
        <Select
            placeholder="Route"
            className="prescription__control--select"
            classNamePrefix="prescription-select"
            closeMenuOnSelect={true}
            onChange={onChangeHandler}
            options={routes}
            value={route}
            isSearchable={false}
            isDisabled={routes.length <= 1}
        />
    );
});

const FormSelect = observer(() => {
    const { filtersStore } = useContext(SearchContext);
    const { forms, form } = filtersStore;

    const onChangeHandler = ({ value }) => {
        filtersStore.selectForm(value);
    };

    return (
        <Select
            placeholder="Form"
            className="prescription__control--select"
            classNamePrefix="prescription-select"
            closeMenuOnSelect={true}
            isSearchable={false}
            onChange={onChangeHandler}
            options={forms}
            value={form}
            isDisabled={forms.length <= 1}
        />
    );
});

const StrengthsSelect = observer(() => {
    const { filtersStore } = useContext(SearchContext);
    const { strengths, strength } = filtersStore;

    const onChangeHandler = ({ value }) => {
        filtersStore.selectStrength(value);
    };
    return (
        <Select
            placeholder="Strengths"
            className="prescription__control--select"
            classNamePrefix="prescription-select"
            closeMenuOnSelect={true}
            isSearchable={false}
            onChange={onChangeHandler}
            options={strengths}
            value={strength}
            isDisabled={strengths.length <= 1}
        />
    );
});

const GetCouponBtn = observer(() => {
    const { packagesStore } = useContext(SearchContext);
    const { state } = packagesStore;

    return (
        <Link to="/card">
            <LoadingBtn btnText="Get Card" isLoading={state !== "done"} />
        </Link>
    );
});

function DrugPrescription({ drug }) {
    const { filtersStore } = useContext(SearchContext);

    useEffect(() => {
        filtersStore.init(drug);
    }, [drug]);

    return (
        <div className="component__prescription">
            <div className="container">
                <div className="row">
                    <div className="component__prescription--controls">
                        <div className="prescription__control">
                            <RouteSelect />
                        </div>
                        <div className="prescription__control">
                            <FormSelect />
                        </div>
                        <div className="prescription__control">
                            <StrengthsSelect />
                        </div>
                        <div className="prescription__control">
                            <PackagesDropdown className="prescription__control--select" />
                        </div>
                    </div>
                    <div className="component__prescription--actions">
                        <GetCouponBtn />
                    </div>
                </div>
            </div>
        </div>
    );
}

DrugPrescription.propTypes = {
    drug: PropTypes.string.isRequired,
};

export default DrugPrescription;
