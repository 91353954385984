import { makeAutoObservable } from "mobx";
export default class Drug {
    id = null;
    type = null;
    ndcs = null;
    name = null;
    fullName = null;
    label = null;

    pharmacy = {};
    pricing = {};

    loading = false;
    hover = false;
    isShowModalInfo = false;

    constructor(json) {
        makeAutoObservable(this, {
            id: false,
            type: false,
            ndcs: false,
            name: false,
            fullName: false,
            label: false,
            pharmacy: false,
            pricing: false,
        });

        this.init(json);
    }

    get ndc() {
        if (this.ndcs !== null) {
            return this.ndcs.ndc;
        } else {
            return "";
        }
    }

    get ndc11() {
        if (this.ndcs !== null) {
            return this.ndcs.package;
        } else {
            return "";
        }
    }

    get pharmacyId() {
        if (this.pharmacy !== {}) {
            return this.pharmacy.id;
        } else {
            return "";
        }
    }

    get pharmacyName() {
        if (this.pharmacy !== {}) {
            return this.pharmacy.name;
        } else {
            return "";
        }
    }

    get pharmacyBaseName() {
        if (this.pharmacy !== {}) {
            return this.pharmacy.baseName;
        } else {
            return "";
        }
    }

    get pharmacyAddress() {
        if (this.pharmacy !== {}) {
            return this.pharmacy.address;
        } else {
            return "";
        }
    }

    get pharmacyDistance() {
        if (this.pharmacy !== {} && this.pharmacy.distance) {
            return `${this.pharmacy.distance} miles`;
        } else {
            return "Less than a mile";
        }
    }

    get pharmacyOpenHours() {
        if (this.pharmacy !== {}) {
            return this.pharmacy.openHours;
        } else {
            return "";
        }
    }

    get pharmacyPhoneNumber() {
        if (this.pharmacy !== {}) {
            return this.pharmacy.phone;
        } else {
            return "";
        }
    }

    get pharmacyCoords() {
        if (this.pharmacy !== {}) {
            if (this.pharmacy.coords !== null) {
                return {
                    latitude: this.pharmacy.coords.lat,
                    longitude: this.pharmacy.coords.lng,
                };
            }
        }
        return null;
    }

    get price() {
        return this.pricing?.total;
    }

    get json() {
        return {
            drug: {
                ndc: this.ndc11,
                name: this.name,
                label: this.label,
            },
            pharmacy: this.pharmacy,
            price: this.price,
            isFavorite: this.isFavorite,
        };
    }

    init(json) {
        this.id = `${json.ndcPackage}-${json.pharmacy.id}`;
        this.ndcs = {
            ndc: json.ndc,
            package: json.ndcPackage,
        };

        this.name = json.name;
        this.fullName = json.fullName;
        this.label = json.label;

        this.pharmacy = json.pharmacy; //?
        this.pricing = json.pricing;
    }

    switchHover(state) {
        this.hover = state;
    }

    setModalInfoWindow(state) {
        this.isShowModalInfo = state;
    }
}
