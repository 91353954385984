import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { getDrugDescription } from "../../../services/search.service";
import { useNavigate } from "react-router-dom";

import DrugSaveUp from "../../../components/drug-save-up/DrugSaveUp";
import SimilarDrugButton from "../../../components/similar-drug-button/SimilarDrugButton";

import SearchList from "../search-result/SearchList";
import MobileLocation from "./mobile-location/MobileLocation";
import MobileDrugPrescription from "./mobile-drug-prescription/MobileDrugPrescription";
import MobileDrugMap from "./mobile-drug-map/MobileDrugMap";
import MobileResultSort from "./mobile-result-sort/MobileResultSort";

import "./search-mobile-content.scss";
function SearchMobileContent({ drug }) {
    const navigation = useNavigate();
    const [drugInfo, setDrugInfo] = useState({});

    useEffect(() => {
        let didCancel = false;
        async function getData(name) {
            setDrugInfo([]); // reset
            const result = await getDrugDescription(name);
            if (!didCancel) {
                if (result !== null) {
                    setDrugInfo(result);
                } else {
                    navigation("/search");
                }
            }
        }
        getData(drug);

        return () => {
            didCancel = true;
        };
    }, [drug]);

    return (
        <div className="page-search-mobile">
            <div className="page-search-mobile__content">
                <div className="container">
                    <div className="row">
                        <DrugSaveUp drug={drug} />
                    </div>
                    <div className="row">
                        <h1>{drugInfo?.title}</h1>
                    </div>
                </div>
                <div className="page-search-mobile__prescription">
                    <div className="container">
                        <div className="row">
                            {drugInfo.similar ? (
                                <SimilarDrugButton similar={drugInfo.similar} />
                            ) : null}
                            <MobileLocation />
                        </div>
                    </div>
                    <MobileDrugPrescription drug={drug} />
                </div>
                <div className="page-search-mobile__result">
                    <div className="page-search-mobile__result--map">
                        <MobileDrugMap />
                    </div>
                    <div className="page-search-mobile__result--list">
                        <div className="page-search-mobile__result-filters">
                            <MobileResultSort />
                        </div>
                        <SearchList />
                    </div>
                </div>
                <div className="page-search-mobile__description">
                    <div className="container">
                        <div className="row">
                            <h2>About</h2>
                            <div className="page-search-mobile__description--content">
                                {drugInfo?.description ? (
                                    <div className="content-item">
                                        <h4>Description</h4>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: drugInfo.description,
                                            }}
                                        />
                                    </div>
                                ) : null}
                                {/* {drugInfo?.pharmacology ? (
                                    <div className="content-item">
                                        <h4>Pharmacology</h4>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: drugInfo.pharmacology
                                                    ?.description,
                                            }}
                                        />
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: drugInfo.pharmacology
                                                    ?.indication,
                                            }}
                                        />
                                    </div>
                                ) : null} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

SearchMobileContent.propTypes = {
    drug: PropTypes.string.isRequired,
};

export default SearchMobileContent;
