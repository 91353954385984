import PropTypes from "prop-types";
import { find } from "lodash";
import classNames from "classnames";

import cnbcLogo from "./images/cnbc.png";
import dailyBeastLogo from "./images/the-daily-beast.png";
import forbesLogo from "./images/forbes.png";
import theGuardianLogo from "./images/the-guardian.png";
import theHillLogo from "./images/the-hill.png";
import yahooNewsLogo from "./images/yahoo-news.png";

import cnbcColorLogo from "./images/cnbc-color.png";
import dailyBeastColorLogo from "./images/the-daily-beast-color.png";
import forbesColorLogo from "./images/forbes-color.png";
import theGuardianColorLogo from "./images/the-guardian-color.png";
import theHillColorLogo from "./images/the-hill-color.png";
import yahooNewsColorLogo from "./images/yahoo-news-color.png";
import usWeeklyColorLogo from "./images/us-weekly-color.png";
import sfExaminerColorLogo from "./images/sf-examiner-color.png";
import fastCompanyColorLogo from "./images/fast-company-color.png";
import abc15ColorLogo from "./images/abc15-color.png";
import benefitNewsColorLogo from "./images/benefit-news-color.png";
import wxyzColorLogo from "./images/wxyz-color.png";
import outcomesRocketColorLogo from "./images/outcomesrocket-color.png";

const List = [
    { name: "cnbc", img: cnbcLogo, alt: "CNBC", link: "https://www.cnbc.com" },
    {
        name: "dailyBeast",
        img: dailyBeastLogo,
        alt: "The Daily Beast",
        link: "https://www.thedailybeast.com/",
    },
    {
        name: "forbes",
        img: forbesLogo,
        alt: "Forbes",
        link: "https://www.forbes.com/",
    },
    {
        name: "guardian",
        img: theGuardianLogo,
        alt: "The Guardian",
        link: "https://www.theguardian.com/",
    },
    {
        name: "hill",
        img: theHillLogo,
        alt: "The Hill",
        link: "https://thehill.com/",
    },
    {
        name: "yahoonews",
        img: yahooNewsLogo,
        alt: "Yahoo News",
        link: "https://news.yahoo.com/",
    },
    // {
    //     name: "us-weekly",
    //     img: usWeeklyLogo,
    //     alt: "Us Weekly",
    //     link: "https://www.usmagazine.com/",
    // },
];

const ListColor = [
    {
        name: "cnbc",
        img: cnbcColorLogo,
        alt: "CNBC",
        link: "https://www.cnbc.com",
    },
    {
        name: "dailyBeast",
        img: dailyBeastColorLogo,
        alt: "The Daily Beast",
        link: "https://www.thedailybeast.com/",
    },
    {
        name: "forbes",
        img: forbesColorLogo,
        alt: "Forbes",
        link: "https://www.forbes.com/",
    },
    {
        name: "guardian",
        img: theGuardianColorLogo,
        alt: "The Guardian",
        link: "https://www.theguardian.com/",
    },
    {
        name: "hill",
        img: theHillColorLogo,
        alt: "The Hill",
        link: "https://thehill.com/",
    },
    {
        name: "yahoonews",
        img: yahooNewsColorLogo,
        alt: "Yahoo News",
        link: "https://news.yahoo.com/",
    },
    {
        name: "us-weekly",
        img: usWeeklyColorLogo,
        alt: "Us Weekly",
        link: "https://www.usmagazine.com/",
    },
    {
        name: "sfexaminer",
        img: sfExaminerColorLogo,
        alt: "San Fransisco Examiner",
        link: "https://www.sfexaminer.com/",
    },
    {
        name: "fastcompany",
        img: fastCompanyColorLogo,
        alt: "Fast Company",
        link: "https://www.fastcompany.com/",
    },
    {
        name: "abc15",
        img: abc15ColorLogo,
        alt: "ABC 15",
        link: "https://www.abc15.com/",
    },
    {
        name: "benefitnews",
        img: benefitNewsColorLogo,
        alt: "Benefit News",
        link: "https://www.benefitnews.com/",
    },
    {
        name: "wxyz",
        img: wxyzColorLogo,
        alt: "WXYZ Detroit",
        link: "https://www.wxyz.com/",
    },
    {
        name: "outcomesrocket",
        img: outcomesRocketColorLogo,
        alt: "Outcomes Rocket",
        link: "https://outcomesrocket.health/",
    },
];

function PressLogo({ name, color = false, containerClassName = "" }) {
    const item = find(color ? ListColor : List, ["name", name]);

    if (item === undefined) return null;

    return (
        <div
            className={classNames({
                [containerClassName]: containerClassName !== "",
            })}
        >
            <a href={item.link} target="_blank" rel="noreferrer">
                <img
                    src={item.img}
                    alt={item.alt}
                    draggable={false}
                    className={classNames({
                        [`press-img-logo__${item.name}`]: true,
                    })}
                />
            </a>
        </div>
    );
}

PressLogo.propTypes = {
    name: PropTypes.string,
    color: PropTypes.bool,
    containerClassName: PropTypes.string,
};

export default PressLogo;
