import PropTypes from "prop-types";
import "./location.scss";

function LocationInput({ value, onChangeHandler, containerStyle, inputStyle }) {
    const onChangeInputHandler = (e) => {
        const _val = e.target.value;
        if (_val !== value) {
            onChangeHandler(_val);
        }
    };

    return (
        <div className={containerStyle}>
            <span>Search by ZIP Code</span>
            <input
                type="text"
                className={`input form-text ${inputStyle}`}
                value={value}
                onChange={onChangeInputHandler}
            />
        </div>
    );
}

LocationInput.propTypes = {
    value: PropTypes.string.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    containerStyle: PropTypes.string,
    inputStyle: PropTypes.string,
};

LocationInput.defaultProps = {
    containerStyle: "",
    inputStyle: "",
};

export default LocationInput;
