import PropTypes from "prop-types";
import { Fragment, useState } from "react";
import ModalWindow from "../../../../components/modal-window/ModalWindow";
import SearchResultMap from "../../search-result-map/SearchResultMap";

import "./mobile-drug-map.scss";

const MobileDrugMapModal = ({ closeHandler }) => {
    return (
        <ModalWindow
            fullscreen={true}
            title="Nearest pharmacies"
            closeHandler={closeHandler}
            className="mobile-drug-map__modal"
        >
            <div className="location-modal">
                <div className="location-modal__content">
                    <SearchResultMap isMobile={true} />
                </div>
            </div>
        </ModalWindow>
    );
};

MobileDrugMapModal.propTypes = {
    closeHandler: PropTypes.func.isRequired,
};

function MobileDrugMap() {
    const [open, switchOpen] = useState(false);
    const onClickHandler = () => {
        switchOpen(!open);
    };

    return (
        <Fragment>
            <div
                className="component__mobile-map-btn"
                onClick={() => onClickHandler()}
            >
                <span className="component__mobile-map-btn--text">
                    View on the map
                </span>
            </div>
            {open ? <MobileDrugMapModal closeHandler={switchOpen} /> : null}
        </Fragment>
    );
}

export default MobileDrugMap;
