import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { kebabCase } from "lodash";

import LoadingBtn from "../loading-btn/LoadingBtn";
import SearchInput from "./SearchInput";
import SearchResultList from "./search-result-list/SearchResultList";

import "./search-bar.scss";

function SearchBar() {
    const navigation = useNavigate();
    const [searchStr, setSearchStr] = useState("");
    const [results, setResults] = useState([]);

    const [btnLoading, setBtnLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [typed, setTyped] = useState(false);

    const onLoadingHandler = (state) => {
        setLoading(state);
        if (state) setResults([]);
    };

    const navigateToSearch = (uri) => {
        setBtnLoading(true);
        setTimeout(() => {
            navigation(`/drug/${uri}`);
        }, 250); // ?
    };

    const onClickBtnHandler = () => {
        if (searchStr !== "") {
            navigateToSearch(kebabCase(searchStr));
        }
    };

    const onClickToResultHandler = ({ displayName, uriName }) => {
        if (displayName && uriName) {
            setSearchStr(displayName);
            navigateToSearch(uriName);
        }
    };

    return (
        <div className="component__searchbar full">
            <div className="component__searchbar--wrap">
                <div className="searchbar__form with-clear with-lens">
                    <SearchInput
                        placeholder="Type in name of prescription or code"
                        searchValue={searchStr}
                        onChangeSearchValue={(s) => setSearchStr(s)}
                        onSearch={(state) => setTyped(state)}
                        onResults={(arr) => setResults(arr)}
                        onLoading={onLoadingHandler}
                    />
                    <LoadingBtn
                        btnText="Search"
                        classNames="searchbar__form--searchbtn"
                        isLoading={btnLoading}
                        click={onClickBtnHandler}
                    />
                </div>
                <div className="component__searchbar--result">
                    {typed && (
                        <SearchResultList
                            loading={loading}
                            items={results}
                            onClicToItem={onClickToResultHandler}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default SearchBar;
