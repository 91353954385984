import { NavLink } from "react-router-dom";
import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";

import PharmacyPartnersList from "../../../components/pharmacy-partners-list/PharmacyPartnersList";
import PressLogo from "../../../components/press-logos/PressLogo";

import DATA from "./data.json";
import "./section-press-partners.scss";

function SectionPressPartners() {
    const items = DATA;

    const [emblaRef] = useEmblaCarousel({ loop: true }, [Autoplay()]);

    return (
        <div className="section__press-partners">
            <div className="container pharmacies">
                <div className="thin-custom-row">
                    <PharmacyPartnersList />
                </div>
            </div>
            <div className="embla">
                <div className="embla__viewport" ref={emblaRef}>
                    <div className="embla__container">
                        {items.map((item, i) => (
                            <div key={i} className="item">
                                <div className="item__logo">
                                    <PressLogo
                                        name={item.magazine}
                                        color={true}
                                        containerClassName="item__logo-wrap"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="section__btn">
                <NavLink to="/press">
                    <button className="button purple">Media Coverage</button>
                </NavLink>
            </div>
        </div>
    );
}

export default SectionPressPartners;
