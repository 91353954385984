import PropTypes from "prop-types";
import PressLogo from "../../../components/press-logos/PressLogo";

import "./press-article-item.scss";

function PressArticleItem({ item }) {
    return (
        <div className="component__press-article-item">
            <div className="thumb">
                {item.thumbSrc && (
                    <img
                        src={require(`../images/articles/${item.thumbSrc}`)}
                        alt={`CharityRx in ${item.magazine}`}
                    />
                )}
            </div>
            <div className="wrap">
                <div className="content">
                    <h3>{item?.title}</h3>
                    <p dangerouslySetInnerHTML={{ __html: item?.excerpt }} />
                </div>
                <div className="footer">
                    <div className="footer__info">
                        {item?.magazine && (
                            <div className="footer__info-press-logo">
                                <PressLogo name={item.magazine} color={true} />
                            </div>
                        )}
                        {item?.createdAt && (
                            <span className="footer__info-date">
                                {item.createdAt}
                            </span>
                        )}
                    </div>
                    {item?.link && (
                        <div className="footer__link">
                            <a href={item.link} target="blank">
                                Read more
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

PressArticleItem.propTypes = {
    item: PropTypes.object.isRequired,
};

export default PressArticleItem;
