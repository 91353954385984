import { useState } from "react";
import { useNavigate } from "react-router-dom";

import SearchResultList from "./search-result-list/SearchResultList";
import SearchInput from "./SearchInput";

import "./search-bar.scss";

function SmallSearchBar() {
    const navigation = useNavigate();
    const [searchStr, setSearchStr] = useState("");
    const [results, setResults] = useState([]);

    const [typed, setTyped] = useState(false);
    const [loading, setLoading] = useState(true);

    const onLoadingHandler = (state) => {
        setLoading(state);
        if (state) setResults([]);
    };

    const onClickToResultHandler = ({ displayName, uriName }) => {
        if (displayName && uriName) {
            setSearchStr(displayName);
            navigation(`/drug/${uriName}`);

            setTimeout(() => {
                setTyped(false);
                setSearchStr("");
            }, 600); // reset search input after choose
        }
    };

    return (
        <div className="component__searchbar small">
            <div className="component__searchbar--wrap">
                <div className="searchbar__form with-clear with-lens">
                    <SearchInput
                        placeholder="Type in name of prescription or code"
                        searchValue={searchStr}
                        onChangeSearchValue={(s) => setSearchStr(s)}
                        onSearch={(state) => setTyped(state)}
                        onResults={(arr) => setResults(arr)}
                        onLoading={onLoadingHandler}
                    />
                </div>
                <div className="component__searchbar--result">
                    {typed && (
                        <SearchResultList
                            loading={loading}
                            items={results}
                            onClicToItem={onClickToResultHandler}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default SmallSearchBar;
