import PropTypes from "prop-types";
import { useState } from "react";
import { QRCodeModal, TextModal, EmailModal } from "./modals/CardRxModals";

import "./rx-card-actions.scss";

const { REACT_APP_CDN_URL } = process.env;

function RxCardActions({ cardName }) {
    const [showQrCode, switchQrCode] = useState(false);
    const [showTextModal, switchTextModal] = useState(false);
    const [showEmailModal, switchEmailModal] = useState(false);

    const getHrefDownloadLink = (name) => {
        if (!REACT_APP_CDN_URL) return null;

        if (name === undefined) {
            return `${REACT_APP_CDN_URL}/pdf/charityrx-card.pdf`;
        }

        return `${REACT_APP_CDN_URL}/pdf/charityrx-card_${name.toUpperCase()}.pdf`;
    };

    const onPrintHandler = (e) => {
        e.preventDefault();
        window.print();
    };

    return (
        <>
            <div className="rxcard__actions">
                <div className="rxcard__actions-item download">
                    <a
                        href={getHrefDownloadLink(cardName)}
                        download="Charity_Rx_card.pdf"
                        style={{ width: "100%" }}
                        rel="noreferrer"
                        target="_blank"
                    >
                        <button className="button purple download-btn">
                            Download
                        </button>
                    </a>
                </div>
                <div className="rxcard__actions-item">
                    <button
                        className="button blue via-sms"
                        onClick={() => switchTextModal(!showTextModal)}
                    >
                        Send via text
                    </button>
                </div>
                <div className="rxcard__actions-item">
                    <button
                        className="button blue via-mail"
                        onClick={() => switchEmailModal(!showEmailModal)}
                    >
                        Send via email
                    </button>
                </div>
                <div className="rxcard__actions-item">
                    <button
                        className="button blue via-qr"
                        onClick={() => switchQrCode(!showQrCode)}
                    >
                        Save QR code
                    </button>
                </div>
                <div className="rxcard__actions-item">
                    <button
                        className="button blue print"
                        onClick={onPrintHandler}
                    >
                        Print coupon
                    </button>
                </div>
            </div>
            {showQrCode ? (
                <QRCodeModal closeHandler={() => switchQrCode(false)} />
            ) : null}
            {showTextModal ? (
                <TextModal
                    cardName={cardName}
                    closeHandler={() => switchTextModal(false)}
                />
            ) : null}
            {showEmailModal ? (
                <EmailModal
                    cardName={cardName}
                    closeHandler={() => switchEmailModal(false)}
                />
            ) : null}
        </>
    );
}

RxCardActions.propTypes = {
    cardName: PropTypes.string,
};

// RxCardActions.defaultProps = {};

export default RxCardActions;
