import PropTypes from "prop-types";
import Slider from "react-input-slider";

import "./location.scss";

function LocationRadiusSlider({ value, onChangeHandler }) {
    return (
        <div className="location--dropdown__radius">
            <p className="location--dropdown__radius--title">
                Search radius <span>{value}</span> miles
            </p>
            <div className="location--dropdown__radius--slider">
                <Slider
                    x={value}
                    xmin={0}
                    xmax={50}
                    onChange={({ x }) => onChangeHandler(x)}
                />
            </div>
        </div>
    );
}

LocationRadiusSlider.propTypes = {
    value: PropTypes.number.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
};

export default LocationRadiusSlider;
