import PropTypes from "prop-types";

import ErrorImg from "./ErrorImg";
import NotFoundErrorImg from "./NotFoundErrorImg";

import "./errors.scss";

function TableError({ type, text }) {
    return (
        <div className="component__tableerror">
            {type === "error" ? <ErrorImg /> : <NotFoundErrorImg />}
            {text ? <p className="error">{text}</p> : null}
        </div>
    );
}

TableError.propTypes = {
    type: PropTypes.oneOf(["error", "not-found"]),
    text: PropTypes.string,
};

TableError.defaultProps = {
    type: "error",
};

export default TableError;
