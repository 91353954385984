import SearchBar from "../../../components/search-bar/SearchBar";
import "./section-hero.scss";
import TrandingCoupons from "./tranding-coupons/TrandingCoupons";

export default function SectionHero() {
    return (
        <div className="section__hero">
            <div className="section__hero--background">
                <div className="hero__images left" />
                <div className="hero__images right" />
            </div>
            <div className="section__hero--content">
                <div className="hero__content--text">
                    <h1>Get free discounts up to 80% on your prescriptions</h1>
                    <p
                        className="subtitle"
                        dangerouslySetInnerHTML={{
                            __html: "Check the cost of your medication with CharityRx\nCharityRx will often save you more than insurance",
                        }}
                    />
                </div>
                <div className="hero__content--search">
                    <span className="hero__search--img" />
                    <SearchBar />
                    <TrandingCoupons />
                </div>
            </div>
        </div>
    );
}
