import PropTypes from "prop-types";
import ListHighlightsItems from "../../components/list-highlights-items/ListHighlightsItems";
import PharmacyPartnersList from "../../components/pharmacy-partners-list/PharmacyPartnersList";
import PageSectionSubscription from "../../components/page-section-subscription/PageSectionSubscription";

// import teamItems from "./team.json";

import "./about-us.scss";

const TeamItem = ({ item }) => {
    return (
        <div className="our-team__item">
            <div className="our-team__item--imgwrap">
                <img src={require(`./team-images/${item.img}`)} />
            </div>
            <div className="our-team__item--content">
                <h4 className="name">{item.name}</h4>
                <p className="position">{item.position}</p>
            </div>
        </div>
    );
};

TeamItem.propTypes = {
    item: PropTypes.object.isRequired,
};

function AboutUsPage() {
    return (
        <div className="page-about-us">
            <div className="container">
                <div className="row">
                    <div className="centered page-about-us__title">
                        <h1 className="page-title">About us</h1>
                        <p className="subtitle">
                            CharityRx was created out of the conviction that
                            access to healthcare is a fundamental human right.
                            We make medication affordable and support charity
                            organizations with every prescription filled. The
                            rising cost of prescription drugs makes access to
                            healthcare seem like a privilege more than a right,
                            leaving millions of Americans with no way of getting
                            the medications they need. We&apos;re changing that.
                        </p>
                    </div>
                    <div className="centered page-about-us__highlights">
                        <ListHighlightsItems />
                    </div>
                    <div className="centered page-about-us__partners">
                        <h2>Our leading partners</h2>
                        <PharmacyPartnersList color={true} />
                    </div>
                    {/* <div className="centered page-about-us__our-team">
                        <h2>Our team</h2>
                        <p className="subtitle">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Donec cursus lectus eleifend sem sodales
                            tincidunt velit pulvinar amet. Cras interdum mi
                            euismod malesuada risus amet.
                        </p>
                        <div className="our-team__wrap">
                            <ul className="our-team__list">
                                {teamItems.map((item, key) => (
                                    <TeamItem key={key} item={item} />
                                ))}
                            </ul>
                        </div>
                    </div> */}
                    <div className="centered page-about-us__our-charityrx">
                        <h2>We Give</h2>
                        <p className="subtitle">
                            We save, we share, we seek equity and wellness for
                            all. As part of our commitment to social
                            responsibility, we donate to a charity with every
                            prescription filled. This means that by simply using
                            our discount cards, you can help uplift the lives of
                            others while saving on your medications. Join us in
                            our mission to make healthcare affordable for every
                            American, and give back to those who need it most.
                        </p>
                        <div className="our-charityrx__img-wrap">
                            <div className="our-charityrx__img-wrap--item">
                                <img
                                    src={require("./charityrx-images/1.png")}
                                    alt="CharityRx"
                                />
                            </div>
                            <div className="our-charityrx__img-wrap--item">
                                <img
                                    src={require("./charityrx-images/2.png")}
                                    alt="CharityRx"
                                />
                            </div>
                            <div className="our-charityrx__img-wrap--item">
                                <img
                                    src={require("./charityrx-images/3.png")}
                                    alt="CharityRx"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <PageSectionSubscription />
                </div>
            </div>
        </div>
    );
}

export default AboutUsPage;
