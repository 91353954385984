import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ListBenefitsItems from "../../components/list-benefits-items/ListBenefitsItems";
import PageSectionSubscription from "../../components/page-section-subscription/PageSectionSubscription";
import RxOrgCard from "../../components/rx-org-card/RxOrgCard";
import RxOrgCardActions from "../../components/rx-org-card-actions/RxOrgCardActions";
import NotFoundPage from "../not-found-page/NotFoundPage";

import "./get-card-page.scss";

const SubtitleDescription = ({ type }) => {
    const [text, setText] = useState(null);

    useEffect(() => {
        switch (type) {
            case "100K12":
                setText(
                    "Download your Cane Bay High School powered by CharityRx discount card and simply present it to the pharmacist for instant savings on your next prescription purchase. Every time you save using this card, you also donate to Cane Bay High School!"
                );
                break;
            case "101K12":
                setText(
                    "Download your Iron County School District powered by CharityRx discount card and simply present it to the pharmacist for instant savings on your next prescription purchase. Every time you save using this card, you also donate to Iron County School District!"
                );
                break;
            case "102K12":
                setText(
                    "Download your Heights High School PTO powered by CharityRx discount card and simply present it to the pharmacist for instant savings on your next prescription purchase. Every time you save using this card, you also donate to Heights High School PTO!"
                );
                break;
            default:
                setText(null);
                break;
        }
    }, [type]);

    return <p className="subtitle">{text}</p>;
};

SubtitleDescription.propTypes = {
    type: PropTypes.string,
};

function GetOrganizationCardPage() {
    const [notFound, setNotFound] = useState(false);
    const { couponCode } = useParams();

    useEffect(() => {
        const AVAILABLE_ROUTES = ["100K12", "101K12", "102K12"];
        setNotFound(!AVAILABLE_ROUTES.includes(couponCode));
    }, [couponCode]);

    if (notFound) {
        return <NotFoundPage />;
    }

    return (
        <div className="page-get-card">
            <div className="container">
                <div className="row">
                    <div className="page-get-card__content">
                        <div className="page-get-card__content--title">
                            <h1 className="page-title">
                                Get Your Coupon Today
                            </h1>
                            <SubtitleDescription type={couponCode} />
                        </div>
                        <div className="page-get-card__content--rxcard">
                            <RxOrgCard type={couponCode} />
                            <RxOrgCardActions type={couponCode} />
                        </div>
                        <div className="page-get-card__content--info">
                            <ListBenefitsItems />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <PageSectionSubscription />
                </div>
            </div>
        </div>
    );
}

export default GetOrganizationCardPage;
