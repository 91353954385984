import PropTypes from "prop-types";
import SearchLoader from "../search-loader/SearchLoader";

import "./search-result-list.scss";

const SearchResultNotFound = () => {
    return (
        <div className="component__searchresult--notfound">
            <p>No matches found. Please try another</p>
        </div>
    );
};

const SearchResultListItem = ({ item, onClick }) => {
    return (
        <li className="searchresult__list--item" onClick={onClick}>
            <div className="searchresult__item--text">
                <span className="title">
                    {item?.displayName ? item.displayName : "Undefined"}
                </span>
                <span className="type">({item?.type})</span>
            </div>
            {/* <div className="searchresult__item--thebest">
                <span>Best price</span>
            </div> */}
        </li>
    );
};

SearchResultListItem.propTypes = {
    item: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
};

function SearchResultList({ loading, items, onClicToItem }) {
    const renderContent = () => {
        if (loading) return <SearchLoader />;

        if (items.length > 0) {
            return (
                <div className="component__searchresult--list">
                    <ul className="searchresult__list">
                        {items.map((item, index) => (
                            <SearchResultListItem
                                key={index}
                                item={item}
                                onClick={() => onClicToItem(item)}
                            />
                        ))}
                    </ul>
                </div>
            );
        } else {
            return <SearchResultNotFound />;
        }
    };

    return <div className="component__searchresult">{renderContent()}</div>;
}

SearchResultList.propTypes = {
    loading: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    onClicToItem: PropTypes.func.isRequired,
};

export default SearchResultList;
