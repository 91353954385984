import { findIndex, get, isEmpty } from "lodash";
import { makeAutoObservable, action, toJS, reaction } from "mobx";

const formSelectData = (obj) =>
    Object.keys(obj).map((item) => ({ label: item, value: item }));

export default class PackagesStore {
    resultList = [];

    current = null;
    default = null;

    customUnit = null;

    state = "pending"; // "pending", "done" or "error"

    constructor(root) {
        makeAutoObservable(this);

        this.root = root;

        this.updateCurrentPackage = reaction(
            () => this.current,
            () => {
                this.initSearch();
            }
        );
    }

    get packages() {
        return toJS(this.resultList);
    }

    get options() {
        if (this.packages !== null) {
            return formSelectData(this.packages);
        } else {
            return [];
        }
    }

    get selected() {
        if (this.customUnit === null) {
            if (this.options[this.current] !== undefined) {
                return this.options[this.current];
            }
        } else {
            const regex = /^\d/gm;
            return {
                value: this.customUnit,
                label: this.options[0].label.replace(regex, this.customUnit),
            };
        }
        return null;
    }

    setCustomUnit(value) {
        this.customUnit = value;
        this.initSearch();
    }

    selectPackage(newItemValue) {
        let newItemKey = findIndex(this.options, ["value", newItemValue]);
        if (newItemKey !== this.current) {
            this.current = newItemKey;
            this.customUnit = null;
        }
    }

    update(ndcs) {
        this.reset();
        this.root.resultStore.reset();
        this.root.service.getPackagesByNdcs(ndcs).then(
            action("fetchSuccess", (result) => {
                if (result) {
                    this.set(result);
                    this.state = "done";
                } else {
                    this.state = "error";
                }
            }),
            action("fetchError", () => {
                this.state = "error";
            })
        );
    }

    set(data) {
        this.resultList = data.packages;
        this.default = data.default;
        if (!isEmpty(this.default)) {
            this.selectPackage(this.default);
        } else {
            if (!isEmpty(this.options)) {
                this.selectPackage(this.options[0].value);
            }
        }
    }

    initSearch() {
        const item = this.getSelectedItem();
        if (item !== null) {
            const unit = this.customUnit === null ? item.unit : this.customUnit;
            this.root.resultStore.search(item.ndcs, unit);
        }
    }

    getSelectedItem() {
        if (this.customUnit !== null) {
            return get(this.packages, this.options[0].value);
        }

        const { selected } = this;
        if (selected && selected.value) {
            return get(this.packages, selected.value);
        } else {
            return null;
        }
    }

    dispose() {
        // So, to avoid subtle memory issues, always call the
        // disposers when the reactions are no longer needed.
        this.updateCurrentPackage();
    }

    reset() {
        this.state = "pending";

        this.resultList = [];
        this.current = null;
        this.default = null;
        this.customUnit = null;
    }
}
