import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { getSampleSavingDrugs } from "../../../services/search.service";
import { currencyFormat } from "../../../utilities/helpers";

import GetACouponBtn from "../../../components/get-a-coupon-btn/GetACouponBtn";
import TableError from "../../../components/errors/TableError";
import Spinner from "../../../components/spinner/Spinner";

import "./section-sample-saving.scss";

const TableErrorContent = ({ type }) => {
    return (
        <div className="samplesaving__table--error">
            <TableError
                text="Sorry, something went wrong, please reload page or try again."
                type={type}
            />
        </div>
    );
};

TableErrorContent.propTypes = {
    type: PropTypes.string,
};

const TableLoading = ({ isDesktop }) => {
    let shimmerItems = [];
    for (let index = 0; index < 2; index++) {
        shimmerItems.push(<TableListMobileShimmerItem key={index} />);
    }

    if (isDesktop) {
        return <Spinner classes="samplesaving__table--loader" />;
    } else {
        return <>{shimmerItems}</>;
    }
};

TableLoading.propTypes = {
    isDesktop: PropTypes.bool.isRequired,
};

const TableListMobileShimmerItem = () => {
    return (
        <li className="samplesaving_table-mobile__item">
            <div className="shimmer-wrapper">
                <div className="samplesaving_table__item--column column-name">
                    <div className="shimmer shimmer-title" />
                    <div className="shimmer shimmer-subtitle" />
                </div>
                <div className="samplesaving_table__item--column column-pharamcy">
                    <div className="shimmer shimmer-text" />
                    <div className="shimmer shimmer-text" />
                </div>
                <div className="samplesaving_table__item--column column-package">
                    <div className="shimmer shimmer-text" />
                </div>
                <div className="samplesaving_table__item--column column-total">
                    <div className="total-block">
                        <div className="shimmer shimmer-price" />
                    </div>
                    <div className="total-block">
                        <div className="shimmer shimmer-button" />
                    </div>
                </div>
            </div>
        </li>
    );
};

const TableListItem = ({ isDesktop, item }) => {
    const {
        name,
        ndcs,
        fullName,
        pharmacyName,
        pharmacyOpenHours,
        packageName,
        total,
    } = item;
    return (
        <li
            className={
                isDesktop
                    ? "samplesaving_table__item"
                    : "samplesaving_table-mobile__item"
            }
        >
            <div className="samplesaving_table__item--column column-name">
                <h3>{name}</h3>
                <p className="subtitle">{fullName}</p>
            </div>
            <div className="samplesaving_table__item--column column-pharamcy">
                <h3>{pharmacyName}</h3>
                <p className="subtitle">{pharmacyOpenHours.join("\n")}</p>
            </div>
            <div className="samplesaving_table__item--column column-package">
                <span>{packageName}</span>
            </div>
            <div className="samplesaving_table__item--column column-total">
                <div className="total-block">
                    <span className="price">{currencyFormat(total)}</span>
                </div>
                <div className="total-block">
                    <GetACouponBtn title="Get Coupon" ndc={ndcs?.package} />
                </div>
            </div>
        </li>
    );
};

TableListItem.propTypes = {
    isDesktop: PropTypes.bool.isRequired,
    item: PropTypes.object.isRequired,
};

export default function SectionSampleSaving() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [tableItems, setTableItems] = useState([]);
    const [isDesktop, setIsDesktop] = useState(true);

    useEffect(() => {
        let didCancel = false;
        async function getData() {
            setLoading(true);
            setError(false);
            const data = await getSampleSavingDrugs();
            setLoading(false);
            if (!didCancel) {
                if (data !== null) {
                    setTableItems(data);
                } else {
                    setError(true);
                }
            }
        }

        getData();
        return () => {
            didCancel = true;
        };
    }, []);

    useEffect(() => {
        const updateWindowDimensions = () => {
            setIsDesktop(window.innerWidth > 1200);
        };
        updateWindowDimensions();
        window.addEventListener("resize", updateWindowDimensions);
        return () =>
            window.removeEventListener("resize", updateWindowDimensions);
    }, []);

    const renderTableItems = () => {
        if (loading === false && error === false && tableItems.length === 0) {
            return <TableErrorContent type="not-found" />;
        } else {
            return (
                <>
                    {tableItems.map((item, i) => (
                        <TableListItem
                            key={i}
                            isDesktop={isDesktop}
                            item={item}
                        />
                    ))}
                </>
            );
        }
    };

    return (
        <div className="section__samplesaving">
            <h2>Sample savings with CharityRx</h2>
            <p className="subtitle">
                Some examples of savings you can get on top medications.
            </p>
            <div className="section__samplesaving--wrap">
                <div className="samplesaving__table">
                    <div className="samplesaving__table--header">
                        <div>drug name</div>
                        <div>pharmacy</div>
                        <div>quantity</div>
                        <div>price with CharityRx</div>
                    </div>
                    <div className="samplesaving__table--content">
                        <ul className="samplesaving__table--list">
                            {loading ? (
                                <TableLoading isDesktop={isDesktop} />
                            ) : null}
                            {error ? <TableErrorContent type="error" /> : null}
                            {renderTableItems()}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
