import { Fragment, useState } from "react";

import ModalMobileLocation from "../../../../components/modal-mobile-location/ModalMobileLocation";
// import { getUserLocation } from "../../../../stores/location.store";

import "./mobile-location-dropdown.scss";

function MobileLocation() {
    const [currentLocationLabel, setCurrentLocationLabel] = useState();
    const [open, setOpen] = useState(false);

    return (
        <Fragment>
            <div
                className="component__mobile-location"
                onClick={() => setOpen(true)}
            >
                <div className="component__mobile-location--block">
                    {currentLocationLabel
                        ? currentLocationLabel
                        : "set up my location"}
                </div>
            </div>
            {/* {open ? ( */}
            <ModalMobileLocation
                show={open}
                onChangeLocation={(zip, radius) =>
                    setCurrentLocationLabel(`${zip} Radius: ${radius} mi`)
                }
                modalCloseHandler={() => setOpen(false)}
            />
            {/* ) : null} */}
        </Fragment>
    );
}

export default MobileLocation;
