import { AspectRatio } from "react-aspect-ratio";

import "./section-how-it-works.scss";

function SectionHowItWorks() {
    return (
        <div className="section__howitworks thin-custom-row">
            <div className="section__howitworks--content">
                <h2>How It Works</h2>

                <div className="howitworks__content">
                    <div className="howitworks__content--item">
                        <AspectRatio
                            ratio={303 / 271}
                            style={{
                                maxWidth: "400px",
                                backgroundImage: `url(${require("./images/step-01-2x.png")})`,
                            }}
                        />
                        <div className="howitworks-item">
                            <span className="howitworks-item--count">
                                Step 1
                            </span>
                            <h3 className="howitworks-item--title">
                                Check and compare prices
                            </h3>
                            <p className="howitworks-item--desc">
                                Search for deep discounts on thousands of
                                FDA-Approved medications in 70,000+ pharmacies.
                            </p>
                        </div>
                    </div>
                    <div className="howitworks__content--item">
                        <AspectRatio
                            ratio={273 / 267}
                            style={{
                                maxWidth: "400px",
                                backgroundImage: `url(${require("./images/step-02-2x.png")})`,
                            }}
                        />
                        <div className="howitworks-item">
                            <span className="howitworks-item--count">
                                Step 2
                            </span>
                            <h3 className="howitworks-item--title">
                                Download your coupon for free
                            </h3>
                            <p className="howitworks-item--desc">
                                Download, email, text, screenshot, or print the
                                card or coupon for redemption.
                            </p>
                            <p className="empty" />
                        </div>
                    </div>
                    <div className="howitworks__content--item">
                        <AspectRatio
                            ratio={255 / 240}
                            style={{
                                maxWidth: "400px",
                                backgroundImage: `url(${require("./images/step-03-2x.png")})`,
                            }}
                        />
                        <div className="howitworks-item">
                            <span className="howitworks-item--count">
                                Step 3
                            </span>
                            <h3 className="howitworks-item--title">
                                Show and save
                            </h3>
                            <p className="howitworks-item--desc">
                                Present your CharityRx discount card or coupon
                                to the pharmacist and receive instant savings of
                                up to 80%.
                            </p>
                        </div>
                    </div>
                    <div className="howitworks__content--item">
                        <AspectRatio
                            ratio={253 / 233}
                            style={{
                                maxWidth: "400px",
                                backgroundImage: `url(${require("./images/step-04-2x.png")})`,
                            }}
                        />
                        <div className="howitworks-item">
                            <span className="howitworks-item--count">
                                Step 4
                            </span>
                            <h3 className="howitworks-item--title">
                                Your health and helping hand
                            </h3>
                            <p className="howitworks-item--desc">
                                Each time you use our discounts, you help us
                                donate to charities in need.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SectionHowItWorks;
