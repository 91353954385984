import PropTypes from "prop-types";
import classnames from "classnames";
import { useState, useContext, useEffect, useRef } from "react";

import { observer } from "mobx-react-lite";
import { SearchContext } from "../../../stores/contexts";

import "./packages-dropdown.scss";

const PackageCustomQuantity = observer(({ onClose }) => {
    const [value, setValue] = useState("");

    const { packagesStore } = useContext(SearchContext);
    const { customUnit } = packagesStore;

    useEffect(() => {
        if (customUnit !== null) setValue(customUnit);
    }, []);

    const onChangeHandler = (e) => {
        const _value = e.target.validity.valid ? e.target.value : value;
        setValue(_value);
    };

    const onClickHandler = () => {
        if (value && value !== customUnit) {
            packagesStore.setCustomUnit(value);
            onClose();
        }
    };

    return (
        <div className="component__package-custom-qty">
            <div className="package-custom-qty">
                <p>Enter custom quantity</p>
                <div className="package-custom-qty__form">
                    <input
                        type="text"
                        pattern="[0-9]*"
                        placeholder="0-999"
                        className="input form-text"
                        value={value}
                        onChange={onChangeHandler}
                    />
                    <button className="button purple" onClick={onClickHandler}>
                        Done
                    </button>
                </div>
            </div>
        </div>
    );
});

PackageCustomQuantity.propTypes = {
    onClose: PropTypes.func.isRequired,
};

const Menu = observer(({ onClose, disabledHandler }) => {
    const { packagesStore } = useContext(SearchContext);
    const { options } = packagesStore;

    useEffect(() => {
        if (options && options.length > 0) {
            disabledHandler(false);
        } else {
            disabledHandler(true);
        }
    }, [options]);

    const onClickHandler = (item) => {
        packagesStore.selectPackage(item.value);
        onClose();
    };

    return (
        <ul className="package-dropdown__list">
            {options.map((item, key) => (
                <li
                    key={key}
                    className="package-dropdown__list--item"
                    onClick={() => onClickHandler(item)}
                >
                    {item.label}
                </li>
            ))}
        </ul>
    );
});

Menu.propTypes = {
    onClose: PropTypes.func.isRequired,
    disabledHandler: PropTypes.func.isRequired,
};

const SingleValue = observer(() => {
    const { packagesStore } = useContext(SearchContext);
    const { selected } = packagesStore;

    if (selected !== null) {
        return <span className="value">{selected?.label}</span>;
    } else {
        return <span className="value placeholder">Packages</span>;
    }
});

function PackagesDropdown({ className }) {
    const dropdownRef = useRef(null);

    const [disabled, switchDisabled] = useState(false);
    const [open, switchOpen] = useState(false);

    const dropdownClassNames = classnames({
        "component__package-dropdown": true,
        "component__package-dropdown--disabled": disabled,
        [className]: className,
    });

    const menuClassNames = classnames({
        "component__package-dropdown--menu": true,
        open: open,
    });

    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            switchOpen(false);
        }
    };

    return (
        <div ref={dropdownRef} className={dropdownClassNames}>
            <div
                className="component__package-dropdown--current"
                onClick={() => switchOpen(!open)}
            >
                <SingleValue />
                <span className="open-icon" />
            </div>
            <div className={menuClassNames}>
                <Menu
                    onClose={() => switchOpen(false)}
                    disabledHandler={switchDisabled}
                />
                <PackageCustomQuantity onClose={() => switchOpen(false)} />
            </div>
        </div>
    );
}

PackagesDropdown.propTypes = {
    className: PropTypes.string,
};

export default PackagesDropdown;
