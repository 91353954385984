import client from "./client";

let controller;

export const DoAutocompleteRequest = async (q) => {
    try {
        if (controller) controller.abort();
        controller = new AbortController();

        const response = await client.post(
            "/",
            {
                action: "drugs/autocomplete",
                data: { q },
            },
            {
                signal: controller.signal,
            }
        );
        return response.data;
    } catch (error) {
        return null;
    }
};
