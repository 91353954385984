import { Link } from "react-router-dom";
import ContactUsForm from "../../components/forms/ContactUsForm";

import "./contact-us.scss";
function ContactUsPage() {
    return (
        <div className="page-contact-us">
            <div className="container">
                <div className="row">
                    <div className="page-contact-us__content">
                        <h1 className="page-title">Contact us </h1>
                        <p className="subtitle">
                            Have any questions? We are here to help.
                        </p>
                    </div>
                    <div className="page-contact-us__form">
                        <div className="page-contact-us__form--wrap">
                            <ContactUsForm />
                            <div className="page-contact-us--additional">
                                <h2>Need to speak to us?</h2>
                                {/* <p className="subtitle">
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. In scelerisque mauris
                                    pellentesque aeneanpellentesque aenean
                                    lacus.
                                </p> */}
                                <div className="additional__items">
                                    <div className="additional__item">
                                        <p className="additional__item--title">
                                            Customer Support
                                        </p>
                                        <span className="additional__item--value">
                                            <Link
                                                to="#"
                                                onClick={(e) => {
                                                    window.location =
                                                        "tel:1-800-711-8154";
                                                    e.preventDefault();
                                                }}
                                            >
                                                1-800-711-8154
                                            </Link>
                                        </span>
                                    </div>
                                    <div className="additional__item">
                                        <p className="additional__item--title">
                                            Email us
                                        </p>
                                        <span className="additional__item--value">
                                            <Link
                                                to="#"
                                                onClick={(e) => {
                                                    window.location =
                                                        "mailto:support@charityrx.com";
                                                    e.preventDefault();
                                                }}
                                            >
                                                support@charityrx.com
                                            </Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ContactUsPage;
