import classnames from "classnames";
import PropTypes from "prop-types";

import logoCvs from "./images/cvs.png";
import logoRiteAid from "./images/rite-aid.svg";
import logoTarget from "./images/target.png";
import logoWalgreens from "./images/walgreens.png";
import logoWalmart from "./images/walmart.png";
import logoPublix from "./images/publix.png";
import logoKroger from "./images/kroger.png";
import logoAlbertsonsMarket from "./images/albertsons_market.png";
import logoSafeway from "./images/safeway.png";
import logoVons from "./images/vons.png";
import logoSmith from "./images/smiths.svg";

import logoNotFound from "./images/oops.png";

import "./pharmacy-logo.scss";

function PharmacyLogo({ pharmacyName }) {
    const getLogoUrl = (pharmacyName) => {
        let url;

        switch (pharmacyName) {
            case "cvs":
                url = logoCvs;
                break;
            case "rite_aid":
                url = logoRiteAid;
                break;
            case "target":
                url = logoTarget;
                break;
            case "walgreens":
                url = logoWalgreens;
                break;
            case "walmart":
                url = logoWalmart;
                break;
            case "publix":
                url = logoPublix;
                break;
            case "kroger":
                url = logoKroger;
                break;
            case "albertsons_market":
                url = logoAlbertsonsMarket;
                break;
            case "safeway":
                url = logoSafeway;
                break;
            case "vons":
                url = logoVons;
                break;
            case "smiths":
                url = logoSmith;
                break;
            default:
                url = logoNotFound;
                break;
        }
        return url;
    };

    const componentClassName = classnames({
        "component__pharmacy-logo": true,
        "not-found": pharmacyName === undefined,
    });

    return (
        <div className={componentClassName}>
            <img
                className={`logo-${pharmacyName ? pharmacyName : "not-found"}`}
                alt={pharmacyName}
                src={getLogoUrl(pharmacyName)}
                draggable="false"
            />
        </div>
    );
}
PharmacyLogo.propTypes = {
    pharmacyName: PropTypes.string,
};

export default PharmacyLogo;
