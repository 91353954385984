import "./list-item-shimmer.scss";

function ListPreferPriceShimmer() {
    return (
        <div className="search-prefer__shimmer shimmer-wrapper">
            <div className="shimmer shimmer-price">$0.00</div>
            <div className="shimmer shimmer-button" />
        </div>
    );
}

export default ListPreferPriceShimmer;
